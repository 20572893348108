import React, { useEffect, useState } from 'react';
import './Profile.css';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Profile() {
  const navigate=useNavigate();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const token = Cookies.get("token");
  const decodedToken = token ? jwtDecode(token) : null; 
  const userEmail = decodedToken ? decodedToken.email : '';

  const [user, setUser] = useState({});
  const [originalUser, setOriginalUser] = useState({});
  const [confirmPassword, setConfirmPassword] = useState('');

  useEffect(() => {
    const fetchUserDetails = async () => {
      if (!userEmail) return; 

      try {
        const response = await fetch(`${BASE_URL}/edit/${userEmail}`);
        const result = await response.json();
        if (response.ok) {
          setUser(result.user);
          setOriginalUser(result.user);
        } else {
          alert(result.error);
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };
    fetchUserDetails();
  }, [BASE_URL, userEmail]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prevUser) => ({ ...prevUser, [name]: value }));
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const isSameData =
      user.name === originalUser.name &&
      user.phone === originalUser.phone &&
      user.address === originalUser.address &&
      (user.password === '' || confirmPassword === '');
    if (isSameData) {
      toast.error('Nothing to update.');
      return;
    }
    if (user.password && user.password !== confirmPassword) {
      alert('Passwords do not match.');
      return;
    }
    try {
      const response = await fetch(`${BASE_URL}/user/update/${userEmail}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(user),
      });

      const result = await response.json();
      if (response.ok) {
        alert(result.message);
         navigate("/dashboard");
        setOriginalUser(user);
      } else {
        alert(`Error updating details: ${result.message}`);
      }
    } catch (error) {
      console.error('Error updating user details:', error);
    }
  };

  const statusStyle = {
    color: user.status === 1 ? 'green' : 'brown',
    fontWeight: 'bold',
  };

  return (
    <div className="edit-contain">
      <h2>Profile</h2>
      <div className="container-edit details-container profile">
        <form onSubmit={handleUpdate} className="edit-form">
          <div className="form-group">
            <label>Name:</label>
            <input
              type="text"
              name="name"
              value={user.name || ''}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label>Email:</label>
           
              <p>{user.email}</p>
          </div>

          <div className="form-group">
            <label>Phone:</label>
            <input
              type="tel"
              name="phone"
              value={user.phone || ''}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label>Status:</label>
            <div style={statusStyle}>
              {user.status === 1 ? 'Enabled' : 'Disabled'}
            </div>
          </div>

          <div className="form-group">
            <label>Address:</label>
            <textarea
              name="address"
              value={user.address || ''}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label>Password:</label>
            <input
              type="password"
              name="password"
              placeholder="Leave blank to keep current password"
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label>Confirm Password:</label>
            <input
              type="password"
              name="confirmPassword"
              placeholder="Leave blank to keep current password"
              onChange={handleConfirmPasswordChange}
            />
          </div>

          <div className="save-back-btn-class">
          <button type="button" className="back-btn" onClick={() => navigate(-1)}>
            Back
          </button>
          <button type="submit" className="submit-button">
            Save Changes
          </button>
        </div>
        </form>
      </div>
      <ToastContainer position='bottom-center'/>
    </div>
  );
}

export default Profile;
