import React, { useEffect, useRef, useState } from "react";
import HeaderEbill from "./HeaderEbill";
import "./Landing.css";

import AboutImg from "./images/about-img.png";
import Logo from "./images/logo2.png";
import Fatoora from "./images/image-fatoora.png";
import Image from "./images/image.png";
import Logo3 from "./images/logo3.png";
import Phase1 from "./images/phase1.png";
import Phase2 from "./images/phase2.png";
import Client1 from "./images/client1.jpg";
import Client2 from "./images/client2.png";
import Client3 from "./images/client3.png";
import Client4 from "./images/client4.jpg";
import Client6 from "./images/client5.jpg";
import Client7 from "./images/client6.png";
import Client8 from "./images/client7.jpg";
import Client9 from "./images/client8.png";
import Client10 from "./images/client9.jpg";
import Client11 from "./images/client10.svg";
import Client12 from "./images/client11.png";
import Client13 from "./images/client12.png";
import Client14 from "./images/client13.png";
import Client15 from "./images/client14.png";
import Client16 from "./images/client15.jpg";
import Client5 from "./images/client16.jpg";
import FooterEbill from "./FooterEbill";
import ScrollTop from "./ScrollTop";

function Landing() {
  const logosSectionRef = useRef(null);
  const zatcaContainerRef = useRef(null);
  const demoContainerRef = useRef(null);
  const [isLogosVisible, setIsLogosVisible] = useState(false);
  const [isZatcaVisible, setIsZatcaVisible] = useState(false);
  const [isDemoVisible, setIsDemoVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.target === logosSectionRef.current) {
          setIsLogosVisible(entry.isIntersecting);
        } else if (entry.target === zatcaContainerRef.current) {
          setIsZatcaVisible(entry.isIntersecting);
        } else if (entry.target === demoContainerRef.current) {
          setIsDemoVisible(entry.isIntersecting);
        }
      },
      { threshold: 0.5 }
    );

    if (logosSectionRef.current) {
      observer.observe(logosSectionRef.current);
    }
    if (zatcaContainerRef.current) {
      observer.observe(zatcaContainerRef.current);
    }
    if (demoContainerRef.current) {
      observer.observe(demoContainerRef.current);
    }

    return () => {
      if (logosSectionRef.current) observer.unobserve(logosSectionRef.current);
      if (zatcaContainerRef.current)
        observer.unobserve(zatcaContainerRef.current);
      if (demoContainerRef.current)
        observer.unobserve(demoContainerRef.current);
    };
  }, []);
  const clients = [
    Client1,
    Client2,
    Client3,
    Client4,
    Client5,
    Client6,
    Client7,
    Client8,
    Client9,
    Client10,
    Client11,
    Client12,
    Client13,
    Client14,
    Client15,
    Client16,
  ];
  
  const [currentIndex, setCurrentIndex] = useState(0);
  const carouselRef = useRef(null);
  
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % (clients.length * 2)); // Cycle through the entire list infinitely
    }, 2000);
  
    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [clients.length]);
  
  // Update the carousel position
  useEffect(() => {
    if (carouselRef.current) {
      const width = carouselRef.current.offsetWidth;
      carouselRef.current.style.transform = `translateX(-${(currentIndex * width) / clients.length}px)`;
    }
  }, [currentIndex, clients.length]);
  

  return (
    <>
      <HeaderEbill />
      <div className="ebill-main">
        {/* Landing Page Content */}
        <div className="landing-page">
          <div className="row">
            <div className="detail-box">
              <h1 style={{ fontSize: "20px" }}>E-INVOICING SAUDI ARABIA</h1>
              <h1 style={{ fontSize: "35px" }}>
                GET ZATCA E-INVOICING PHASE 2 COMPLIANT
              </h1>
              <p>
                - Get ZATCA E-Invoicing Phase 1 & Phase 2 ready and compliant
                solution instantly.
              </p>
              <p>
                - Connect your existing accounting solution to Margin API and
                get ZATCA compliant.
              </p>
              <p>
                - Implement ZATCA E-Invoicing Saudi Arabia requirements in your
                existing solution.
              </p>
            </div>
          </div>

          {/* About Image */}
          <div className="img-box">
            <img src={AboutImg} alt="About" />
          </div>
        </div>
      </div>

      {/* Company Logos Section */}
      <div
        className={`logos-section ${isLogosVisible ? "fade-in" : ""}`}
        ref={logosSectionRef}
      >
        <p className="logos-heading">
          200+ companies are already using <br />
          E-BILL E-Invoicing
        </p>

        <div className="logos-container">
          <div className="logo">
            <img src={Fatoora} alt="Company 1" className="company-logo" />
          </div>
          <div className="logo">
            <img src={Logo} alt="Company 2" className="company-logo" />
          </div>
          <div className="logo">
            <img src={Image} alt="Company 3" className="company-logo" />
          </div>
        </div>
      </div>

      {/* Demo Section */}
      <div
        className={`demo-container ${isDemoVisible ? "fade-in" : ""}`} // Add fade-in class
        ref={demoContainerRef} // Reference for demo container
      >
        <h3 style={{ fontSize: "30px", color: "white" }}>
          Want to see how E-Bill Saudia can help you with e-invoicing
          first-hand?
        </h3>
        <a href="#" className="demo-btn">
          Book a Demo
        </a>
      </div>

      {/* ZATCA Section */}

      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <div className="zatca-container">
          <div
            className={`inside-zatca-container ${
              isZatcaVisible ? "fade-in" : ""
            }`}
            ref={zatcaContainerRef}
          >
            <h3 style={{ textAlign: "center" }}>
              ZATCA Approved E-Invoicing Solution
            </h3>
            <p>
              <span style={{ fontWeight: "bold" }}>
                E-Bill Saudia E-Invoicing Solution is Approved & Certified by
                ZATCA and fully compliant with E-Invoicing Saudi Arabia
                Regulations
              </span>{" "}
              issued by Zakat, Tax and Customs Authority (ZATCA) in Saudi
              Arabia.
            </p>
            <p>Verify E-Bill Saudia from ZATCA Website: Click Here.</p>
            <p>
              Our E-Invoicing Solution has been built on a highly reliable
              structure which ensures maximum durability, accessibility and
              security as well as the agility to adapt to any changes and
              additional requirements by ZATCA.
            </p>
            <p>
              <span style={{ fontWeight: "bold" }}>
                After successful Phase 1 implementation, E-Bill Saudia
                E-Invoicing Solution is now fully ready for Phase 2 and to
                provide a hassle free integration with ZATCA starting January 1,
                2023.
              </span>
            </p>
            <button className="demo-btn">Book Demo</button>
          </div>
          <div
            className={`zatca-container-wrapper ${
              isZatcaVisible ? "zoom-animation" : ""
            }`}
            ref={zatcaContainerRef}
          >
            <div className="inside-zatca-container-1">
              <img src={Logo3} alt="Logo3"></img>
            </div>
          </div>
        </div>
      </div>
      <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
        <div>
          <div style={{ textAlign: "center" }}>
            <h3
              style={{ fontSize: "40px", fontWeight: "500", color: "#666666" }}
            >
              E-Invoicing In Saudi Arabia
            </h3>
            <p style={{ fontSize: "18px" }}>
              Learn all about the features offered by E-Bill Saudia
            </p>
          </div>
        </div>

        <div className="phases-container">
          <div className="phase-1">
            <h1>Phase 1</h1>
            <p>December 04, 2021</p>
            <img src={Phase1}></img>
          </div>
          <div className="phase-2">
            <h1>Phase 2</h1>
            <p>January 01, 2023</p>
            <img src={Phase2}></img>
          </div>
        </div>
      </div>
      <div className="clients-section">
  <h1>Some of Our Clients</h1>
  <div className="carousel-container">
    <div className="carousel-inners" ref={carouselRef}>
      {/* Main clients */}
      {clients.map((client, index) => (
        <div className="slide" key={index}>
          <img src={client} alt={`Client ${index + 1}`} />
        </div>
      ))}
      {/* Duplicated clients for infinite loop */}
      {clients.map((client, index) => (
        <div className="slide" key={`duplicate-${index}`}>
          <img src={client} alt={`Client ${index + 1} Duplicate`} />
        </div>
      ))}
    </div>
  </div>
</div>

      <ScrollTop />
      <FooterEbill />
    </>
  );
}

export default Landing;
