import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import "./Categories.css";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { Link } from "react-router-dom";

function Payment() {
  const [packages, setPackages] = useState([]);
   const [packagesUser, setPackagesUser] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const token = Cookies.get("token");
  const [isAdmin, setIsAdmin] = useState(false);
  let userEmail = "";

  // Fetch packages from the backend
  useEffect(() => {
    if (token) {
      const decodedToken = jwtDecode(token);
      setIsAdmin(decodedToken.isAdmin);
      userEmail = decodedToken.email;
    }
    const fetchPackages = async () => {
      try {
        const response = await fetch(`${BASE_URL}/api/plans/admin`);
        if (!response.ok) throw new Error("Failed to fetch packages");
        const data = await response.json();
        setPackages(data);
      } catch (error) {
        console.error("Error fetching packages:", error);
      }
    };
    fetchPackages();

    const Packages = async () => {
      try {
        const response = await fetch(`${BASE_URL}/api/plans`);
        if (!response.ok) throw new Error("Failed to fetch packages");
        const data = await response.json();
        setPackagesUser(data);
      } catch (error) {
        console.error("Error fetching packages:", error);
      }
    };
    Packages();

  }, [BASE_URL]);

  const handleDelete = async (plan_name) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this plan?"
    );

    if (isConfirmed) {
      try {
        const response = await fetch(`${BASE_URL}/api/plans/${plan_name}`, {
          method: "DELETE",
        });

        if (!response.ok) {
          throw new Error(`Failed to delete plan. Status: ${response.status}`);
        }

        alert("Plan deleted successfully");
        console.log("Before deletion:", packages);
        setPackages((prevPlans) => {
          const updatedPlans = prevPlans.filter(
            (plan) => plan.plan_name !== plan_name
          );
          console.log("After deletion:", updatedPlans);
          return updatedPlans;
        });
      } catch (error) {
        console.error("Delete error:", error);
        alert("There was an error deleting the plan.");
      }
    }
  };

  return (
    <div className="product-container">
      {isAdmin ? (
        <div className="admin-content">
          <h2>Admin Controls</h2>
          <Link to="/dashboard/create-pkg">
            <button className="create-package-button">
              + Create New Package
            </button>
          </Link>
          <div className="table-container">
            <table className="package-table">
              <thead>
                <tr>
                  <th>Plans</th>
                  <th>Amount</th>
                  <th>Duration</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {packages.map((pkg) => (
                  <tr key={pkg.id}>
                    <td>{pkg.plan_name}</td>
                    <td>{pkg.amount} SAR</td>
                    <td>{pkg.duration}</td>
                    <td>
                      <span className={`status ${pkg.status.toLowerCase()}`}>
                        {pkg.status.charAt(0).toUpperCase() +
                          pkg.status.slice(1)}
                      </span>
                    </td>
                    <td>
                      <Link
                        to="/dashboard/plans-edit"
                        onClick={() =>
                          localStorage.setItem("plan_name", pkg.plan_name)
                        }
                      >
                        <button className="edit-button edit">
                          <FaEdit />
                        </button>
                      </Link>

                      <button
                        className="delete-button red"
                        onClick={() => handleDelete(pkg.plan_name)}
                      >
                        <FaTrashAlt />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <>
          <h2>Our Products</h2>
          <div className="product-boxes">
            {packagesUser.map((pkg) => (
              <div className="product-boxes-container">
                <div className="product-box" key={pkg.id}>
                  <h1>{pkg.plan_name}</h1>
                  <div className="inside-product-box">
                    <h3>{pkg.amount} SAR</h3>
                    <h4>{pkg.duration} Months</h4>
                    {pkg.features &&
                      Array.isArray(pkg.features) &&
                      pkg.features.map((feature, index) => (
                        <p key={index}>{feature}</p>
                      ))}
                    <Link
                      to="/dashboard/payments"
                      state={{ selectedPlan: pkg }}
                    >
                      <button>Buy Now</button>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default Payment;
