import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom"; // Import useLocation hook
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
function RenewalPayments() {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const location = useLocation();
  const { selectedPayment } = location.state || {};
  const [user, setUser] = useState({});
  const token = Cookies.get("token");
  let isAdmin = false;
  let userEmail = "";
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  if (token) {
    const decodedToken = jwtDecode(token);
    isAdmin = decodedToken.isAdmin;
    userEmail = decodedToken.email;
  }
  useEffect(() => {
    const fetchUserDetails = async () => {
      if (!token) return;
      const decodedToken = jwtDecode(token);
      const userEmail = decodedToken.email;
      try {
        const response = await fetch(`${BASE_URL}/edit/${userEmail}`);
        const result = await response.json();
        if (response.ok) {
          setUser(result.user);
        } else alert(result.error);
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };
    fetchUserDetails();
  }, [BASE_URL, token]);
  const handleNext = () => {
     



  };
  return (
    <div>
      <div className="container-edit details-container payments">
        <div className="edit-form">
          <div className="form-group">
            <label>Name:</label>
            <p>{user.name || ""}</p>
          </div>
          <div className="form-group">
            <label>Email:</label>
            <p>{user.email || ""}</p>
          </div>
          <div className="form-group">
            <label>Phone:</label>
            <p>{user.phone || ""}</p>
          </div>
          <div className="form-group">
            <label>Status:</label>
            <div
              style={{
                color: user.status === 1 ? "green" : "brown",
                fontWeight: "bold",
              }}
            >
              {user.status === 1 ? "Enabled" : "Disabled"}
            </div>
          </div>
          <div className="form-group">
            <label>Address:</label>
            <p>{user.address || ""}</p>
          </div>
          <div className="save-back-btn-class">
            <button
              type="button"
              className="back-btn"
              onClick={() => navigate(-1)}
            >
              Back
            </button>
            <button
              type="button"
              className="submit-button"
              onClick={handleNext}
              disabled={loading}
            >
              {loading ? "Processing Payment..." : "Next for Payment"}
            </button>
          </div>
        </div>
        <p className="para-text">
          If you want to change your details, you can update them from the 
          <Link to="/dashboard/profile"> profile</Link> section.
        </p>
      </div>
    </div>
  );
}

export default RenewalPayments;
