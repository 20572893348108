import React, { useEffect, useState } from "react";
import { useNavigate, Routes, Route } from "react-router-dom"; 
import './Dash.css';
import Header from './Header';
import Sidebar from './Sidebar';
import Home from './Home';
import Users from './Users'; 
import EditUser from "./EditUser";
import AddUsers from "./AddUsers";
import Subscriptions from "./Subscriptions"
import Details from "./Details";
import AddProducts from "./AddProducts";
import Categories from "./Categories";
import Profile from "./Profile";
import Cookies from 'js-cookie';
import Invoices from "./Invoices";
import EditPlans from "./EditPlans";
import CreatePKg from "./Create_pkg";
import Payments from "./Payments";
import RenewalPayments from "./RenewalPayments";
import Faqs from "./Faqs";
import ImportDomain from "./importDomain";

function Dash() {
  const [openSidebarToggle, setOpenSidebarToggle] = useState(true); 
  const navigate = useNavigate();

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };

  useEffect(() => {
    const checkAuthToken = async () => {
      const authToken = Cookies.get("token");
      if (!authToken) {
                navigate("/login");
      } else {
        try {
          const response = await fetch(`${process.env.REACT_APP_BASE_URL}/verify-token`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${authToken}`
            }
          });
  
          if (!response.ok) {
            throw new Error('Token is invalid');
          }
        } catch (error) {
          console.error('Error:', error);
          Cookies.remove("token");
          navigate("/login");
        }
      }
    };
    checkAuthToken();
  }, [navigate]);
  
  // Logout function to clear the token and navigate to the login page
  const handleLogout = () => {
    Cookies.remove("token")
    navigate("/login");
  };

  return (
    <div className={`grid-container ${openSidebarToggle ? '' : 'sidebar-closed'}`}>
      <Header OpenSidebar={OpenSidebar} Logout={handleLogout} />
      <Sidebar openSidebarToggle={openSidebarToggle} OpenSidebar={OpenSidebar} />
      <div className="content">
        <Routes>
          <Route index element={<Home />} /> 
          <Route path="users" element={<Users />} />
          <Route path="edit" element={<EditUser />} />
          <Route path="add-users" element={<AddUsers />} />
          <Route path="subscriptions" element={<Subscriptions />} />
          <Route path="details" element={<Details />} />
          <Route path="add_products" element={<AddProducts />} />
          <Route path="profile" element={<Profile />} />
          <Route path="categories" element={<Categories/>}/>
          <Route path="invoices" element={<Invoices/>}/>
          <Route path="plans-edit" element={<EditPlans/>}/>
          <Route path="create-pkg" element={<CreatePKg/>}/>
          <Route path="payments"   element={<Payments/>}/>
          <Route path="renewalpayments" element={<RenewalPayments/>}/>
          <Route path="faqs" element={<Faqs/>}/>
          <Route path="import" element={<ImportDomain/>}/>
        </Routes>
      </div>
    </div>
  );
}

export default Dash;
