import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./Details.css";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { FaExternalLinkAlt } from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Details() {
  const navigate = useNavigate();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const location = useLocation();
  const { subscription_id, name,email } = location.state;
  const [product, setProduct] = useState({});
  const [originalProduct, setOriginalProduct] = useState({});
  const [isAdminUser, setIsAdminUser] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const token = Cookies.get("token");
  const [plans, setPlans] = useState([]);
  const [selectedPlanName, setSelectedPlanName] = useState("");
  const [plan,setPlan]=useState(null);
  let isAdmin = false;
  if (token) {
    const decodedToken = jwtDecode(token);
    isAdmin = decodedToken.isAdmin;
  }

  useEffect(() => {
    if (!isAdmin) {
      setIsAdminUser(true);
    }

    if (subscription_id) {
      const fetchDetails = async () => {
        try {
          const productResponse = await fetch(
            `${BASE_URL}/detailproduct/${subscription_id}`
          );
          const productData = await productResponse.json();
          if (productResponse.ok) {
            setOriginalProduct(productData.products);
            setProduct(productData.products);
            setPlan(productData.products.plan_name);
            setPlans(productData.plans);
          } else {
            alert(productData.message);
          }
        } catch (err) {
          console.error("Error fetching details:", err.message);
          alert("Error fetching details: " + err.message);
        }
      };

      fetchDetails();
    } else {
      console.error("User email is undefined or not provided.");
    }
  }, [subscription_id, BASE_URL]);

  const handleChange = (e, type) => {
    const { name, value } = e.target;
    if (type === "product") {
      setProduct((prevProduct) => {
        const updatedProduct = { ...prevProduct, [name]: value };
        return updatedProduct;
      });
    }
  };

  const hasChanges = () => {
    return (
      product.plan_name !== originalProduct.plan_name ||
      product.expiry_date !== originalProduct.expiry_date ||
      product.start_date !== originalProduct.start_date ||
      product.status !== originalProduct.status ||
      product.url !== originalProduct.url ||
      product.id !== originalProduct.id ||
      product.password !== originalProduct.password ||
      product.domain_name !== originalProduct.domain_name
    );
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    if (!hasChanges()) {
      toast.error("No changes made to the details.");
      return;
    }
    if (product.status === "deleted") {
      const promptDelete = window.prompt(
        "You are about to delete important data. Please type 'CONFIRM' to proceed:"
      );

      if (promptDelete !== "CONFIRM") {
        alert("Deletion process canceled.");
        return;
      }
    }

    try {
      console.log(product)
      const response = await fetch(
        `${BASE_URL}/updateproduct/${subscription_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...product,
            password: product.password ? product.password : undefined,
            name, 
            email,
          }),
        }
      );

      const result = await response.json();

      if (response.ok) {
        alert(result.message);
        setOriginalProduct(product);
        navigate("/dashboard/subscriptions");
      } else {
        alert(result.error);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleBack = (e) => {
    e.preventDefault();
    navigate(-1);
  };
  const handleUpgradeClick = () => {
    if (!selectedPlan) {
      toast.error("Please select a plan before upgrading.");
      return;
    }
    // Navigate to the payments page with state
    navigate("/dashboard/payments", {
      state: { selectedPlan, subscription_id },
    });
  };
  return (
    <div className="edit-contain">
      <h2>
        Details{" "}
        <p style={{ fontSize: "20px" }}>
          Subscription Id: {product.subscription_id}
        </p>
      </h2>
      <div className="container-edit details-container">
        {isAdmin ? (
          <form onSubmit={handleUpdate} className="edit-form">
            {/* Package Name */}
            <div className="form-group">
              <label>Package Name:</label>
              <div className="input-container">
                <select
                  name="plan_name"
                  value={product.plan_name}
                  onChange={(e) => handleChange(e, "product")}
                  required
                >
                  <option value={product.plan_name}>{product.plan_name}</option>
                  {plans.length > 0 &&
                    plans.map((plan, index) => {
                      if (plan.plan_name !== product.plan_name) {
                        return (
                          <option key={index} value={plan.plan_name}>
                            {plan.plan_name}
                          </option>
                        );
                      }
                      return null;
                    })}
                </select>
              </div>
            </div>

            <div className="form-group">
              <label>Creation Date:</label>
              <div className="input-container">
                <input
                  type="date"
                  name="start_date"
                  value={product.start_date || ""}
                  onChange={(e) => handleChange(e, "product")}
                  required
                />
              </div>
            </div>

            {/* Expiry Date */}
            <div className="form-group">
              <label>Expiry Date:</label>
              <div className="input-container">
                <input
                  type="date"
                  name="expiry_date"
                  value={product.expiry_date || ""}
                  onChange={(e) => handleChange(e, "product")}
                  required
                />
              </div>
            </div>

            {/* User Fields */}
            <div className="form-group">
              <label>Customer Name:</label>
              <div className="input-container">
                <p>{name}</p>
              </div>
            </div>

            <div className="form-group">
              <label>Domain Name:</label>
              <div className="input-container">
                <input
                  type="text"
                  name="domain_name"
                  value={product.domain_name || ""}
                  onChange={(e) => handleChange(e, "product")}
                />
              </div>
            </div>

            {/* Credentials Section */}
            <div className="credentials-container">
              <h3>Credentials:-</h3>

              <div className="form-group">
                <label>URL:</label>
                <div className="input-container">
                  <input
                    type="text"
                    name="url"
                    value={product.url || ""}
                    onChange={(e) => handleChange(e, "product")}
                  />
                </div>
              </div>

              <div className="form-group">
                <label>ID:</label>
                <div className="input-container">
                  <input
                    type="text"
                    name="id"
                    value={product.id || ""}
                    onChange={(e) => handleChange(e, "product")}
                  />
                </div>
              </div>

              <div className="form-group">
                <label>Password:</label>
                <div className="input-container">
                  <input
                    type="text"
                    name="password"
                    value={product.password || ""}
                    onChange={(e) => handleChange(e, "product")}
                  />
                </div>
              </div>
            </div>

            {/* Status */}
            <div className="form-group">
              <label>Status:</label>
              <div className="input-container">
                <select
                  name="status"
                  value={product.status || "active"}
                  onChange={(e) => handleChange(e, "product")}
                >
                  <option value="active">Active</option>
                  <option value="suspended">Suspended</option>
                  <option value="deleted">Deleted</option>
                  <option value="progress">Progress</option>
                  <option value="create">Create</option>
                </select>
              </div>
            </div>
            <div className="save-back-btn-class">
              <button className="back-btn" onClick={handleBack}>
                Back
              </button>
              <button type="submit" className="submit-button">
                Save Changes
              </button>
            </div>
          </form>
        ) : (
          <div className="view-only-details">
            {/* Package Name */}
            <div className="form-group">
              <label>Package Name:</label>
              <p>{plan}</p>
            </div>

            {/* Creation Date */}
            <div className="form-group">
              <label>Creation Date:</label>
              <p>{product.start_date}</p>
            </div>

            {/* Expiry Date */}
            <div className="form-group">
              <label>Expiry Date:</label>
              <p>{product.expiry_date}</p>
            </div>

            {/* Client Name */}
            {isAdmin ? (
              <div className="form-group">
                <label>Client Name:</label>
                <p>{name}</p>
              </div>
            ) : null}

            {/* Credentials Section */}
            <div className="credentials-container">
              <h3>Credentials:</h3>
              <div className="form-group">
                <label>URL:</label>
                <p>
                  <a
                    href={`https://${product.url}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#0f4b60" }}
                  >
                    {product.url}
                    <FaExternalLinkAlt style={{ marginLeft: "20px" }} />
                  </a>
                </p>
              </div>

              <div className="form-group">
                <label>ID:</label>
                <p>{product.id}</p>
              </div>

              <div className="form-group">
                <label>Password:</label>
                <p>{product.password}</p>
              </div>
            </div>

            {/* Status */}
            <div className="form-group">
              <label>Status:</label>
              <p>
                {" "}
                <span
                  style={{
                    display: "inline-block",
                    borderRadius: "4px",
                    padding: "2px 8px",
                    fontWeight: "bold",
                    color:
                      product.status === "active"
                        ? "#4caf50"
                        : product.status === "suspended"
                        ? "#9e9e9e"
                        : product.status === "progress"
                        ? "#2196F3"
                        : product.status === "create"
                        ? "Orange"
                        : "#8b4513",

                    border: `2px solid ${
                      product.status === "active"
                        ? "#4caf50"
                        : product.status === "suspended"
                        ? "#9e9e9e"
                        : product.status === "progress"
                        ? "#2196F3"
                        : product.status === "create"
                        ? "Orange"
                        : "#8b4513"
                    }`,
                  }}
                >
                  {product.status === "active"
                    ? "ACTIVE"
                    : product.status === "suspended"
                    ? "SUSPENDED"
                    : product.status === "progress"
                    ? "PROGRESS"
                    : product.status === "create"
                    ? "CREATED"
                    : "DELETED"}
                </span>
              </p>
            </div>
            <div className="form-group">
              <label>Package Name:</label>
              <div className="input-container" style={{ textAlign: "end" }}>
                <select
                  name="plan_name"
                  value={product.plan_name} // Use selectedPlanName for value
                  onChange={(e) => {
                    const selectedPlanName = e.target.value;

                    // Update the selected plan name
                    setSelectedPlanName(selectedPlanName);

                    // Find and update the selected plan details
                    const selectedPlan = plans.find(
                      (plan) => plan.plan_name === selectedPlanName
                    );

                    if (selectedPlan) {
                      setSelectedPlan(selectedPlan); // Store full selected plan details
                      setProduct((prevProduct) => ({
                        ...prevProduct,
                        plan_name: selectedPlan.plan_name, // Update product if needed
                      }));
                    }
                  }}
                  required
                  style={{ width: "60%" }}
                >
                  <option value="">Select Plan</option>
                  {plans.length > 0 &&
                    plans.map((allplan, index) => {
                      // Exclude the current plan (product.plan_name) from the dropdown
                      if (allplan.plan_name !== plan) {
                        return (
                          <option key={index} value={allplan.plan_name}>
                            {`${allplan.plan_name} - SAR ${allplan.amount}`}
                          </option>
                        );
                      }
                      return null;
                    })}
                </select>
              </div>
            </div>

            <div className="save-back-btn-class" style={{ marginTop: "0" }}>
              <button className="back-btn" onClick={handleBack}>
                Back
              </button>
              <button
                className="submit-button"
                style={{ backgroundColor: "#4CAF50" }}
                onClick={handleUpgradeClick}
              >
                Upgrade
              </button>
            </div>
          </div>
        )}
      </div>
      <ToastContainer position="bottom-center" />
    </div>
  );
}

export default Details;
