import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./EditPlans.css";
import { FaTrashAlt } from "react-icons/fa";

function Create_pkg() {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();

  const [planData, setPlanData] = useState({
    amount: "",
    duration: "",
    status: "active",
    priority:"",
    features: [],
  });

  const handleAddFeature = () => {
    setPlanData((prevData) => ({
      ...prevData,
      features: [...prevData.features, ""]
    }));
  };

  const handleDeleteFeature = (index) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this feature?");
    if (isConfirmed) {
      const updatedFeatures = planData.features.filter((_, i) => i !== index);
      setPlanData((prevData) => ({
        ...prevData,
        features: updatedFeatures,
      }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPlanData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFeatureChange = (index, value) => {
    const updatedFeatures = [...planData.features];
    updatedFeatures[index] = value;
    setPlanData((prevData) => ({
      ...prevData,
      features: updatedFeatures,
    }));
  };

  const handleSave = async (e) => {
    e.preventDefault();
    console.log(planData);
    try {
      const response = await fetch(`${BASE_URL}/api/plans/addplan`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(planData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const result = await response.json();
      alert(result.message);
      navigate("/dashboard/categories");
    } catch (err) {
      console.error("Save error:", err);
      alert("There was an error saving the changes.");
    }
  };

  return (
    <div className="edit-plan-container">
      <h2>Create New Plan</h2>
      <form className="edit-plan-form" onSubmit={handleSave}>
      <div className="form-group">
          <label htmlFor="paln_name">Plan Name :</label>
          <div className="value-field">
            <input
              type="text"
              name="plan_name"
              value={planData.plan_name}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        {/* Amount Display */}
        <div className="form-group">
          <label htmlFor="amount">Amount (SAR):</label>
          <div className="value-field">
            <input
              type="number"
              name="amount"
              value={planData.amount}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        {/* Duration Display */}
        <div className="form-group">
          <label htmlFor="duration">Duration (Months):</label>
          <div className="value-field">
            <input
              type="number"
              name="duration"
              value={planData.duration}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        {/* Status Display */}
        <div className="form-group">
          <label htmlFor="status">Status:</label>
          <div className="value-field">
            <select
              name="status"
              value={planData.status}
              onChange={handleChange}
              required
            >
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
              required
            </select>
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="Priority">Priority:</label>
          <div className="value-field">
          <input
              type="number"
              name="priority"
              value={planData.priority}
              onChange={handleChange}
            />
          </div>
        </div>

        {/* Features Section */}
        <div className="form-group">
          <div style={{ display: "flex", fontSize: "28px" }}>
            <label className="features">Features:</label>
            <label className="add-features" onClick={handleAddFeature}>
              +
            </label>
          </div>
          <div className="features-containers">
            {planData.features.map((feature, index) => (
              <div key={index} className="feature-items">
                <div className="feature-field" style={{ color: "black" }}>
                  <label>{index + 1}</label>
                  <input
                    type="text"
                    value={feature}
                    onChange={(e) => handleFeatureChange(index, e.target.value)}
                  />
                  <button
                    type="button"
                    onClick={() => handleDeleteFeature(index)}
                    className="delete-btn dlt"
                  >
                    <FaTrashAlt className="trash-btn" />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Buttons */}
        <div className="save-back-btn-class">
          <button type="button" className="back-btn" onClick={() => navigate(-1)}>
            Back
          </button>
          <button type="submit" className="submit-button">
            Save Changes
          </button>
        </div>
      </form>
    </div>
  );
}

export default Create_pkg;
