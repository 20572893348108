import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./AddProducts.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function AddProducts() {
  const navigate = useNavigate();
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const [userDetail, setUserDetail] = useState([]); // For users
  const [planDetail, setPlanDetail] = useState([]); // For plans
  const [sites, setSites] = useState([]); // For subscriptions
  const [initialSites, setInitialSites] = useState([]); // To store the initial state of sites
  const [isChanged, setIsChanged] = useState(false); // Flag to track changes

  useEffect(() => {
    fetchDetails();
  }, []);

  const fetchDetails = async () => {
    try {
      const response = await fetch(`${BASE_URL}/get/Subcripions`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const details = await response.json();
      if (response.ok) {
        console.log(details);
        setUserDetail(details.users || []); // Initialize with empty array if undefined
        setPlanDetail(details.plans || []);
        setSites(details.siteLogs || []); // Set siteLogs that contain subscription details
        setInitialSites(details.siteLogs || []);
      } else {
        toast.error(details.error || "Failed to fetch data.");
      }
    } catch (error) {
      console.error("Error fetching details:", error);
      toast.error("An error occurred while fetching data.");
    }
  };

  const handleChange = (e, id) => {
    const { name, value } = e.target;
    setSites((prevSites) => {
      const updatedSites = prevSites.map((site) =>
        site.id === id ? { ...site, [name]: value } : site
      );
      // Check if any change occurred
      setIsChanged(!areSitesEqual(updatedSites, initialSites));
      return updatedSites;
    });
  };
  const areSitesEqual = (updatedSites, initialSites) => {
    // Compare the updated sites with initial sites
    return JSON.stringify(updatedSites) === JSON.stringify(initialSites);
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    if (!isChanged) {
      toast.info("No changes were made.");
      return; // If no changes, do not proceed with update
    }
    try {
      const response = await fetch(`${BASE_URL}/add-imported-subscription`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(sites),
      });
      const result = await response.json();
      if (response.ok) {
        alert(result.message || "Subscriptions updated successfully!");
        navigate(-1);
      } else {
        toast.error(result.error || "Failed to update subscriptions.");
      }
    } catch (error) {
      console.error("Error updating subscriptions:", error);
      toast.error("An error occurred while updating subscriptions.");
    }
  };

  const handleBack = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  return (
    <div className="edit-contain">
      <h2>Imported Domain</h2>
      <div className="container-edit details-container" style={{ maxWidth: "1050px" }}>
        <form onSubmit={handleUpdate} className="edit-form">
          <table className="table">
            <thead>
              <tr>
                <th>Domain Name</th>
                <th>Select Package</th>
                <th>Expiry Date</th>
                <th>User</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {sites.length > 0 ? (
                sites.map((site) => (
                  <tr key={site.id}>
                    <td>{site.name}</td>
                    <td>
                      <select
                        name="plan_name"
                        value={site.plan_name || ""} // Fill plan if available
                        onChange={(e) => handleChange(e, site.id)}
                        required
                      >
                        <option value="">Select Package</option>
                        {planDetail.map((pkg) => (
                          <option key={pkg.plan_name} value={pkg.plan_name}>
                            {pkg.plan_name}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td>
                      <input
                        type="date"
                        name="expiry_date"
                        value={site.expiry_date || ""} // Fill expiry date if available
                        onChange={(e) => handleChange(e, site.id)}
                        required
                      />
                    </td>
                    <td>
                      <select
                        name="email"
                        value={site.email || ""} // Fill email if available
                        onChange={(e) => handleChange(e, site.id)}
                        required
                      >
                        <option value="">Select User</option>
                        {userDetail.map((user) => (
                          <option key={user.email} value={user.email}>
                            {user.name} - {user.email}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td>
                      <select
                        name="status"
                        value={site.status || ""} // Fill status if available
                        onChange={(e) => handleChange(e, site.id)}
                        required
                      >
                        <option value="">Select Status</option>
                        <option value="active">Active</option>
                        <option value="progress">Progress</option>
                        <option value="suspended">Suspended</option>
                        <option value="deleted">Deleted</option>
                      </select>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5">No subscriptions available.</td>
                </tr>
              )}
            </tbody>
          </table>

          <div className="save-back-btn-class">
            <button className="back-btn" onClick={handleBack}>
              Back
            </button>
            <button type="submit" className="submit-button">
              Save Changes
            </button>
          </div>
        </form>
      </div>
      <ToastContainer position="bottom-center" />
    </div>
  );
}

export default AddProducts;
