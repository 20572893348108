import React, { useEffect, useState } from "react";
import "./header.css";
import {
  BsFillBellFill,
  BsFillEnvelopeFill,
  BsPersonCircle,
  BsSearch,
  BsJustify,
} from "react-icons/bs";
import { IoWallet } from "react-icons/io5";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
function Header({ OpenSidebar, Logout }) {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [isWalletOpen, setIsWalletOpen] = useState(false);
  const token = Cookies.get("token");
  const [isAdmin, setIsAdmin] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [walletBalance, setWalletBalance] = useState("N/A");

  useEffect(() => {
    if (token) {
      const decodedToken = jwtDecode(token);
      setIsAdmin(decodedToken.isAdmin);
      setUserEmail(decodedToken.email);
    }
  }, []);
  const toggleProfileMenu = () => {
    setIsWalletOpen(false);
    setIsProfileOpen(!isProfileOpen);
  };
  const toggleWallet = () => {
    setIsProfileOpen(false);
    setIsWalletOpen(!isWalletOpen);
    fetchWallet();
  };
  const fetchWallet = async (e) => {
    try {
      const response = await fetch(`${BASE_URL}/get/wallet/${userEmail}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();
      if (response.ok) {
        setWalletBalance(result.wallet);
      } else {
        alert(result.error);
      }
    } catch (err) {
      console.err(err);
    }
  };

  return (
    <header className="header">
      <div className="menu-icon">
        <BsJustify className="icon" onClick={OpenSidebar} />
      </div>
      <div className="header-right">
        {!isAdmin && (
          <div className="wallet-container">
            <IoWallet className="icon" onClick={toggleWallet} />
            {isWalletOpen && (
              <div className="wallet-dropdown">
                <IoIosCloseCircleOutline
                  className="icon close"
                  onClick={toggleWallet}
                />
                <br></br>
                <p className="wallet-balance">
                  Your wallet Balance is : <strong>{walletBalance}</strong>
                </p>
              </div>
            )}
          </div>
        )}
        <BsFillBellFill className="icon" />
        <BsFillEnvelopeFill className="icon" />

        <div className="profile-container" onClick={toggleProfileMenu}>
          <BsPersonCircle className="icon profile-icon" />
          {isProfileOpen && (
            <div className="profile-dropdown">
              <IoIosCloseCircleOutline
                className="icon close"
                onClick={toggleProfileMenu}
              />
              <ul>
                <li>
                  <Link to="/dashboard/profile">Profile</Link>
                </li>
                <li>
                  <a href="#">Setting</a>
                </li>
                <li>
                  <a href="#" onClick={Logout}>
                    Logout
                  </a>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </header>
  );
}

export default Header;
