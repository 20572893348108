import React from "react";
import HeaderEbill from "./HeaderEbill";
import FooterEbill from "./FooterEbill";
function Refundpolicy() {
  return (
    <>
      <HeaderEbill />
      <div style={{ marginBottom: "100px" }}>
        <div className="guides" style={{ paddingBottom: "0px" }}>
          <h1 style={{ color: "#0f4b60" }}>Refund Policy</h1>
          <div className="line"></div>
        </div>
        <div className="privacy" style={{ padding: "0px 30px" }}>
          <div>
            <h2
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: "23px",
                margin: "10px 0px",
              }}
            >
              Standard Refund Terms
            </h2>
            <p style={{ color: "#0f4b60", fontSize: "18px" }}>
              Products purchased from SOLEXNET may be refunded only if canceled
              within 30 days of the date of the transaction. Note: Due to their
              nature, cryptocurrencies, tokens and digital assets are generally
              irreversible and their exchange rates are highly volatile and
              transitory. We cannot be responsible for any risk including but
              not limited to exchange rate risk and market risk. Products
              purchased using cryptocurrencies, tokens or digital assets will
              not be refunded. Note: If a client's actions are found to violate
              applicable laws or SOLEXNET's Terms of Services, any payments made
              to SOLEXNET will not be refunded. “Date of the transaction,” for
              the purpose of this Refund Policy, means the date of purchase of
              any product or service, which includes the date any renewal is
              processed by SOLEXNET in accordance with the terms and conditions
              of the applicable product or service agreement (Terms of Use). You
              may cancel a product at any time, but a refund will only be issued
              if cancellation is requested within the refund timeframe specified
              for the applicable product, if available at all. Note: Some
              products have different policies or requirements for a refund
              associated with them, including some products that are not
              eligible for a refund under any circumstance. Please see below for
              refund terms applicable to such products.
            </p>
          </div>
          <div>
            <h2
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: "23px",
                margin: "10px 0px",
              }}
            >
              Refunds To Balance
            </h2>
            <p style={{ color: "#0f4b60", fontSize: "18px" }}>
              We provide refunds to the original funding source. But if you
              would like to use funds to purchase another SOLEXNET service
              instantly, you can select a refund to your SOLEXNET Balance.
              Please note that once a refund to SOLEXNET Balance is initiated
              for a selected invoice, it becomes irrevocable and cannot be
              refunded to a different source.
            </p>
          </div>
          <div>
            <h2
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: "23px",
                margin: "10px 0px",
              }}
            >
              Refunds From Balance
            </h2>
            <p style={{ color: "#0f4b60", fontSize: "18px" }}>
              Over-funded balance can be refunded within 30 days of the payment
              that resulted in the over-funding. In special cases, other
              payments can be refunded instead of the original payment if the
              30-day time-frame can be applied to those payments.
            </p>
          </div>
          <div>
            <h2
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: "23px",
                margin: "10px 0px",
              }}
            >
              Chargebacks
            </h2>
            <p style={{ color: "#0f4b60", fontSize: "18px" }}>
              If at any time, we record a decline, chargeback, reversal, payment
              dispute, risk of payment fraud or other rejection of a charge of
              any payable fees on your SOLEXNET account (“Chargeback”), this
              will be considered as a breach of your payment obligations
              hereunder, therefore you agree that SOLEXNET may pursue all
              available lawful remedies in order to obtain payment, including
              but not limited to, immediate termination, without notice to you,
              of your SOLEXNET account and any domain names or SOLEXNET services
              registered or renewed on your behalf (“Services”). In addition,
              this will also result in disabling the option to checkout with a
              credit card for your SOLEXNET account to limit the risk of payment
              fraud. In the event a Chargeback is performed, your SOLEXNET
              account may be blocked without the option to re-purchase or re-use
              it, and any data contained in such a SOLEXNET account, including
              any certain content, features, or capacity of your SOLEXNET
              account may be subject to cancellation and loss of data. Your use
              of the SOLEXNET Services and the ability to checkout using credit
              card will not resume until you:verify the payment method used for
              the disputed transaction, either by providing: proof of the
              payment; or a simple censored photo of the credit card, with
              uncovered first 6 (six) and last 4 (four) digits. pay any
              applicable fees in full, including any fees and expenses incurred
              by SOLEXNET and/or any third party services for each Chargeback
              received (including fees for SOLEXNET Services provided prior to
              the Chargeback, handling and processing charges and fees incurred
              by the payment processor). Cases related to criminal fraud
              chargebacks or obvious payment fraud (i.e. cases where compromised
              credit card details were used to make purchases) will result in
              permanent service termination without any option to recover. If
              you have any questions or concerns regarding a payment made to
              SOLEXNET, we encourage you to first contact our Customer Support
              team before filing a Chargeback or reversal of payment, in order
              to prevent the SOLEXNET Services from being canceled and your
              SOLEXNET account being blocked, and to avoid the filing of an
              unwarranted or erroneous Chargeback, which may result in your
              being liable for its applicable fees, in addition to re-payment of
              all the fees applicable to the SOLEXNET Services purchased (and
              charged-back) by you. We reserve our right to dispute any
              Chargeback received, including by providing the relevant credit
              card company or financial institution with any information and
              documentation proving that the User responsible for such
              Chargeback did in fact authorize the transaction and make use of
              the Services rendered thereafter.
            </p>
          </div>
        </div>
      </div>
      <FooterEbill />
    </>
  );
}

export default Refundpolicy;
