import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "./AddUser.css";
import { useNavigate } from "react-router-dom";
const AddUser = () => {
  const navigate=useNavigate();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
    wallet_balance:0.00,
    address: "",
    status: 1,
  });
  const handlePhoneChange = (value) => {
    setFormData({
      ...formData,
      phone:value,
    });
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const updatedStatus = name === "status" ? Number(value) : formData.status;
    
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
      status: updatedStatus,
    }));
  };

  const handleAddUser = async (e) => {
    e.preventDefault();
    const { name, email, phone, password,wallet_balance, address, status } = formData;
    if (name === "" || email === "" || phone === "" || password === "") {
      alert("Please fill data in all required fields !");
      return;
    }
    const newUser = {
      name,
      email,
      phone:"+"+phone, 
      password,
      wallet_balance,
      address,
      status,
    };
    try {
      const response = await fetch(`${BASE_URL}/add-user`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newUser),
      });
      const data = await response.json();
      if (response.ok) {
        alert(data.message);
        navigate(-1);
      } else {
        console.error(data.error);
        alert(data.error);
      }
    } catch (err) {
      console.error("Error:", err.message);
      alert("An error occurred while adding the user.");
    }
  };

  return (
    <div className="edit-contain">
      <h2>Add User</h2>
      <div className="container-edit">
        <form onSubmit={handleAddUser} className="edit-form">
          <div className="form-group">
            <label>Name:</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label>Email:</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label>Phone:</label>
            <PhoneInput
              country={"sa"}
              name="phone"
              enableSearch={true}
              value={formData.phone}
              countryCodeEditable={false}
              onChange={handlePhoneChange}
              required
             inputStyle={{
              height: " 100%",
              borderRadius: "5px",
              fontSize: "14px",
              marginLeft:"7%",
            }} 
              containerClass="phone-input"
            />
          </div>
            
          <div className="form-group">
            <label>Password:</label>
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label>Address:</label>
            <textarea
              name="address"
              value={formData.address}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label>Wallet Balance:</label>
            <input
              type="wallet_balance"
              name="wallet_balance"
              value={formData.wallet_balance}
              onChange={handleInputChange}
            />
          </div>

          <div className="form-group">
            <label>Status:</label>
            <select
              name="status"
              value={formData.status}
              onChange={handleInputChange}
            >
              <option value={1}>Enabled</option>
              <option value={0}>Disabled</option>
            </select>
          </div>
          <div className="save-back-btn-class">
          <button type="button" className="back-btn" onClick={() => navigate(-1)}>
            Back
          </button>
          <button type="submit" className="submit-button">
            Save Changes
          </button>
        </div>
        </form>
      </div>
    </div>
  );
};

export default AddUser;
