import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Plans.css";
import FooterEbill from "./FooterEbill";
import HeaderEbill from "./HeaderEbill";

const BASE_URL = process.env.REACT_APP_BASE_URL; 

function Plans() {
  const [packages, setPackages] = useState([]); // State for storing fetched plans

  // Fetch plans on component mount
  useEffect(() => {
    const fetchPackages = async () => {
      try {
        const response = await fetch(`${BASE_URL}/api/plans`);
        if (!response.ok) throw new Error("Failed to fetch packages");
        const data = await response.json();
        setPackages(data);
        console.log(data)
      } catch (error) {
        console.error("Error fetching packages:", error);
      }
    };

    fetchPackages();
  }, []);

  return (
    <>
    <div style={{height:"100%",display:"flex",flexDirection:"column"}}>
      <div>
      <HeaderEbill/>
      </div>
    <div className="plans-pricing" style={{flex:"1",paddingBottom:"100px"}}>
      <h2 style={{color:"#0f4b60",fontWeight:"bold",paddingTop:"20px"}}>Our Products</h2>
      <div className="lines"></div>
      <div className="product-boxes">
        {packages.length > 0 ? (
          packages.map((pkg) => (
            <div className="product-boxes-container" key={pkg.id}>
              <div className="product-box">
                <h1>{pkg.plan_name}</h1>
                <div className="inside-product-box">
                  <h3>{pkg.amount} SAR</h3>
                  <h4>{pkg.duration} Months</h4>
                  {pkg.features?.length > 0 ? (
                    pkg.features.map((feature, index) => (
                      <p key={index}>{feature}</p>
                    ))
                  ) : (
                    <p>No features available</p>
                  )}
                  <Link to="/login" state={{ selectedPlan: pkg }}>
                    <button>Buy Now</button>
                  </Link>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>Loading plans...</p> // Display a loading message while fetching data
        )}
      </div>
      </div>
      <div>
    <FooterEbill/>
    </div>
    </div>
    </>
  );
}

export default Plans;
