import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode"; // Ensure you have jwt-decode installed

function Home() {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [totalUsers, setTotalUsers] = useState(null);
  const [totalSubscriptions, setTotalSubscriptions] = useState(null);
  const [totalUnpaid, setTotalUnpaid] = useState(null);
  const [totalExpiringUsers, setTotalExpiringUsers] = useState(null);
  const [loading, setLoading] = useState(true);
  const [subscriptions, setSubscriptions] = useState([]);
  const [expiryDate, setExpiryDate] = useState(null);
  const [totalActiveSubscriptions, setTotalActiveSubscriptions] = useState(0);
  const [totalSuspendedSubscriptions, setTotalSuspendedSubscriptions] = useState(0);
  const token = Cookies.get("token");
  let isAdmin = false;
  let userEmail = "";

  if (token) {
    const decodedToken = jwtDecode(token);
    isAdmin = decodedToken.isAdmin;
    userEmail = decodedToken.email;
  }

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([
        fetchTotalUsers(),
        fetchTotalSubscriptions(),
        fetchExpiringUsers(),
        fetchUnpaid(),
         fetchStatusSubscriptions(),
        !isAdmin && fetchExpiryDate(),
      ]);
      setLoading(false);
    };

    fetchData();
  }, [isAdmin]);

  const fetchTotalUsers = async () => {
    try {
      const response = await fetch(`${BASE_URL}/total-users`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        const data = await response.json();
        setTotalUsers(data.total_users);
      } else {
        console.error("Error fetching total users.");
      }
    } catch (err) {
      console.error("Error:", err.message);
    }
  };

  const fetchTotalSubscriptions = async () => {
    try {
      const response = await fetch(`${BASE_URL}/total-subscriptions`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        const data = await response.json();
        setTotalSubscriptions(data.total_subscriptions);
      } else {
        console.error("Error fetching total subscriptions.");
      }
    } catch (err) {
      console.error("Error:", err.message);
    }
  };

  const fetchStatusSubscriptions = async () => {
    try {
      const response = await fetch(`${BASE_URL}/subscriptions/status`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        const data = await response.json();
        setTotalActiveSubscriptions(data.total_active);
        setTotalSuspendedSubscriptions(data.total_suspended);
      } else {
        console.error("Error fetching total subscriptions.");
      }
    } catch (err) {
      console.error("Error:", err.message);
    }
  };

  const fetchUnpaid = async () => {
    try {
      const token = Cookies.get("token"); // Get token from cookies
      let isAdmin = false;
      let userEmail = "";

      if (token) {
        const decodedToken = jwtDecode(token); // Decode the JWT token
        isAdmin = decodedToken.isAdmin; // Get admin status
        userEmail = decodedToken.email; // Get user email
      }

      let queryParams;

      // If admin, only send isAdmin as true and no email
      if (isAdmin) {
        queryParams = new URLSearchParams({
          isAdmin: "true", // For admin
        });
      } else {
        // If normal user, send isAdmin as false and their email
        queryParams = new URLSearchParams({
          isAdmin: "false", // For normal user
          email: userEmail, // Send email
        });
      }

      const response = await fetch(
        `${BASE_URL}/total-unpaid?${queryParams.toString()}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setTotalUnpaid(data.total_unpaid); // Update the state with the unpaid invoices count
        console.log("Unpaid Invoices:", data);
      } else {
        console.error("Error fetching unpaid invoices.");
      }
    } catch (err) {
      console.error("Error:", err.message);
    }
  };

  const fetchExpiringUsers = async () => {
    try {
      const response = await fetch(`${BASE_URL}/expiringUsers`, {
        method: "GET",
      });
      const result = await response.json();
      if (response.ok) {
        setTotalExpiringUsers(result.user_count);
      } else {
        console.error("Error fetching expiring users:", result.error);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchExpiryDate = async () => {
    try {
      const response = await fetch(
        `${BASE_URL}/subscriptionDetail/${userEmail}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const result = await response.json();
      if (response.ok) {
        setSubscriptions(result.data);
        console.log(subscriptions)
      } else {
        console.error("Error fetching expiry date:", result.error);
      }
    } catch (err) {
      console.error("Error:", err);
    }
  };



  return (
    <section className="main1-container">
      <div className="main-title">
        <h3>DASHBOARD</h3>
      </div>

      <div className="main-cards">
        <div className="card">
          {!isAdmin ? (
            <>
              <div className="card-inner">
                <h3 style={{textDecoration:"underline"}}>Product Subscribed</h3>
              </div>
              {subscriptions.length > 0 ? (
                <ul style={{listStyle:"none",margin:"0px",padding:"0px",color:"#0f4b60"}}>
                  {subscriptions.map((sub, index) => (
                    <li key={index}>
                      <h4 style={{color:"inherit"}}>{index+1}. {sub.plan_name}</h4>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No subscriptions found.</p>
              )}
            </>
          ) : (
            <>
              <div className="card-inner">
                <h3>Total Subscriptions</h3>
              </div>
              <h1 style={{ color: "grey" }}>{totalSubscriptions}</h1>
            </>
          )}
        </div>
        {isAdmin ? (
           <>
          <div className="card">
          <div className="card-inner">
            <h3>Active Subcripions</h3>
          </div>
          <h1>{totalActiveSubscriptions}</h1>
          </div>
          <div className="card">
          <div className="card-inner">
            <h3>Suspended Subcripions</h3>
          </div>
          <h1>{totalSuspendedSubscriptions}</h1>
          </div>
         </>
        ):(null)}

        <div className="card" style={{ cursor: "pointer" }}>
          <Link
            to="/dashboard/invoices"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <div
              className="card-inner"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <h3>Total Unpaid Invoices </h3>
              <h3>{totalUnpaid}</h3>
            </div>
          </Link>
        </div>
        <div className="card">
          {isAdmin ? (
            <Link
              to="/dashboard/subscriptions"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <div className="card-inner">
                <h3>Expiring products this month</h3>
              </div>
              <h1>{totalExpiringUsers}</h1>
            </Link>
          ) : (
            <>
              <div className="card-inner">
                <h3 style={{textDecoration:"underline"}}>Expiry Date</h3>
              </div>
              {subscriptions.length > 0 ? (
                <ul style={{listStyle:"none",margin:"0px",padding:"0px",color:"#0f4b60"}}>
                  {subscriptions.map((sub, index) => (
                    <li key={index}>
                      <h4 style={{color:"inherit"}}>{index+1}. {sub.expiry_date}</h4>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No Expiry found.</p>
              )}
            </>
          )}
        </div>

       
          {isAdmin ? (
            <>
             <div className="card">
              <div className="card-inner">
                <h3>Total Users</h3>
              </div>
              <h1>{totalUsers}</h1>
              </div>
            </>
          ) : null}
      </div>
    </section>
  );
}

export default Home;
