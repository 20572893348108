import React, { useState, useEffect } from 'react';
import './HeaderEbill.css';
import Logo from './images/logo2.png';
import { Link, useLocation } from 'react-router-dom';

function HeaderEbill() {
  const [scrolled, setScrolled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation(); // Get the current location

  // Scroll event handler
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const isActive = (path) => location.pathname === path; // Check if the path matches the current route

  return (
    <header className={`header-ebill ${scrolled ? 'scrolled' : ''}`}>
      <div className="logo-container">
        <img src={Logo} alt="Logo" />
      </div>
      <div
        className={`hamburger ${menuOpen ? 'open' : ''}`}
        onClick={() => setMenuOpen(!menuOpen)}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
      <nav className={`navbars ${menuOpen ? 'open' : ''}`}>
        <ul className="navbar-list">
          <li className={`navbar-item ${isActive('/') ? 'active' : ''}`}>
            <Link to="/">Home</Link>
          </li>
          <li className={`navbar-item ${isActive('/features') ? 'active' : ''}`}>
            <Link to="/features">Features</Link>
          </li>
          <li className={`navbar-item ${isActive('/pricing') ? 'active' : ''}`}>
            <Link to="/pricing">Pricing</Link>
          </li>
          <li className={`navbar-item ${isActive('/guide-faqs') ? 'active' : ''}`}>
            <Link to="/guide-faqs">Guide & FAQ</Link>
          </li>
          <li className={`navbar-item ${isActive('/contactus') ? 'active' : ''}`}>
            <Link to="/contactus">Contact Us</Link>
          </li>
          <li className={`navbar-item ${isActive('/login') ? 'active' : ''}`}>
            <Link to="/login">Login</Link>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default HeaderEbill;
