import React, { useEffect, useState } from "react";
import "./InvoiceDetail.css";
import ebillLogo from "./images/logo2.png";
import { FaCloudDownloadAlt, FaPrint, FaShareAlt } from "react-icons/fa";
import { MdExpandMore } from "react-icons/md";
import { Link, useLocation, useNavigate } from "react-router-dom";
const InvoiceDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { userEmail, invoice_no } = location.state;
  const [ispaid, setIsPaid] = useState(false);
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [invoiceDetails, setInvoiceDetails] = useState([]);
  const [wallet, setWallet] = useState(null);
  const [iswallet, setIsWallet] = useState(false);
  const handlePrint = () => {
    window.print();
  };
  const [isPaypal, setIsPaypal] = useState(true);
  // Handle download invoice
  const handleDownloadInvoice = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/download-invoice`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...invoiceDetails,
          expiry_date: invoiceDetails.expiry_date || "N/A",
        }),
      });
      if (!response.ok) throw new Error("Invoice generation failed");

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `Invoice_Ebill_${invoice_no}.pdf`;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Failed to download invoice:", error);
      alert("Could not download the invoice. Please try again.");
    }
  };
  useEffect(() => {
    handelInvoiceDetails();
  }, [userEmail]);
  const handelInvoiceDetails = async () => {
    try {
      const response = await fetch(`${BASE_URL}/invoicedetail`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: userEmail, invoice_no }),
      });
      const result = await response.json();
      if (response.ok) {
        console.log(result);
        setInvoiceDetails(result.InvoiceDetails || []);
        if (result.InvoiceDetails.status === "paid") {
          setIsPaid(true);
        }
        if (result.InvoiceDetails.status === "unpaid") {
          fetchWallet();
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  const fetchWallet = async () => {
    try {
      const response = await fetch(`${BASE_URL}/get/wallet/${userEmail}`);
      const result = await response.json();

      if (response.ok) {
        setWallet(result.wallet);
        // You can now update your state or handle the wallet balance as needed
      } else {
        console.error("Error fetching wallet:", result.error);
      }
    } catch (error) {
      console.error("Failed to fetch wallet:", error);
    }
  };

  const commission = (invoiceDetails.amount * 0.03).toFixed(2);
  // Ensure commission and selectedPlan.amount are numbers
  const totalWithCommission = (
    Number(commission) + Number(invoiceDetails.amount)
  ).toFixed(2);
  const handleWallet = () => {
    setIsPaypal(false);
    setIsWallet(true); // Correcting the state update
  };
  const handleContinueClick = () => {
    const selectedPayment = iswallet ? "wallet" : isPaypal ? "paypal" : "ccavenue";
    navigate("/dashboard/payments", {
      state: { selectedPlan:invoiceDetails,selectedPayment },
    });
  };

  return (
    <div className="invoice-detail">
      <div className="invoice-header">
        <div className="logo-section">
          <h4>Invoice Owner</h4>
          <img src={ebillLogo} alt="eBill Logo" className="logo" />
        </div>
        <div>
          <span className="line"></span>
          <p style={{ fontWeight: "bold" }}>{invoiceDetails.user_name}</p>
          <p>{invoiceDetails.address}</p>
          <p>{invoiceDetails.email}</p>
        </div>
      </div>

      <div className="invoice-status">
        <div className="invoice-container">
          <h2>Invoice #{invoiceDetails.invoice_no}</h2>
          <div>
            <div style={{ textAlign: "left" }}>
              <p>support@ebillsaudia.com</p>
              <p
                style={{
                  marginTop: "30px",
                  padding: "10px 0px",
                  borderBottom: "1px solid #ddd",
                }}
              >
                Creation Date:{" "}
                {invoiceDetails.start_date
                  ? invoiceDetails.start_date.split(" ")[0]
                  : "N/A"}
              </p>
              <p
                style={{ padding: "10px 0px", borderBottom: "1px solid #ddd" }}
              >
                Last Payment Date:{" "}
                {invoiceDetails.last_payment_date
                  ? invoiceDetails.last_payment_date.split(" ")[0]
                  : "N/A"}
              </p>
            </div>
          </div>
        </div>
        <div className="inside-invoice-status">
          {!ispaid ? (
            <div className="status unpaid">UNPAID</div>
          ) : (
            <>
              <div className="status paid">
                PAID
                <p style={{ fontWeight: "100", fontSize: "15px" }}>
                  {invoiceDetails.payment_date}
                </p>
              </div>
            </>
          )}

          <div className="action-buttons">
            {/* <button>
              <FaShareAlt style={{ marginRight: "5px" }} />
              Share Invoice
            </button> */}
            <button onClick={handlePrint}>
              <FaPrint style={{ marginRight: "5px" }} />
              Print
            </button>
            <button className="download-button" onClick={handleDownloadInvoice}>
              <FaCloudDownloadAlt style={{ marginRight: "5px" }} />
              Download
            </button>
          </div>
        </div>
      </div>

      <div className="invoice-table">
        <table>
          <thead style={{backgroundColor:"#0f4b60",color:"#ddd"}}>
            <tr>
              <th>Description</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {invoiceDetails.plan_name} - {invoiceDetails.domain_name} (
                {invoiceDetails.payment_date?.split(" ")[0] || "N/A"} to{" "}
                {invoiceDetails.expiry_date?.split(" ")[0] || "N/A"})
              </td>
              <td>SAR {invoiceDetails.amount}</td>
            </tr>
            {!ispaid && (
              <>
                {isPaypal ? (
                  <>
                    <tr>
                      <td>Payment commission with PayPal (3%)</td>
                      <td>SAR {commission}</td>
                    </tr>
                    <tr className="subtotal">
                      <td style={{ textAlign: "right" }}>Subtotal</td>
                      <td>SAR {totalWithCommission}</td>
                    </tr>
                    <tr className="total">
                      <td style={{ textAlign: "right" }}>Amount Payable</td>
                      <td>SAR {totalWithCommission}</td>
                    </tr>
                  </>
                ) : (
                  <>
                    <tr className="subtotal">
                      <td style={{ textAlign: "right" }}>Subtotal</td>
                      <td>SAR {invoiceDetails.amount}</td>
                    </tr>
                    <tr className="total">
                      <td style={{ textAlign: "right" }}>Amount Payable</td>
                      <td>SAR {invoiceDetails.amount}</td>
                    </tr>
                  </>
                )}
              </>
            )}

            {ispaid && (
              <>
                <tr className="subtotal">
                  <td style={{ textAlign: "right" }}>Subtotal</td>
                  <td>SAR {invoiceDetails.amount}</td>
                </tr>
                <tr className="total">
                  <td style={{ textAlign: "right" }}>Amount Payable</td>
                  <td>SAR {invoiceDetails.amount}</td>
                </tr>
              </>
            )}
          </tbody>
        </table>
      </div>

      <div className="payment-section">
        {!ispaid ? (
          <>
            <p className="payment-button">
              <MdExpandMore style={{ marginRight: "5px" }} />
              Make a Payment
            </p>
            <div className="payment-method">
              <p>Payment Method</p>
              <span className="payment-line"></span>
              <div>
                <label onClick={() => {setIsPaypal(true);setIsWallet(false)}}>
                  <input type="radio" name="payment" defaultChecked />
                  <span>PayPal (+3%)</span>
                </label>
                <label onClick={handleWallet}>
                  <input type="radio" name="payment" />
                  <span style={{ marginRight: "5px" }}> Pay by Wallet</span>
                </label>
              </div>
              {iswallet && wallet !== null && (
                <div style={{display:"flex",justifyContent:"end",paddingRight:"100px"}}>
                  <p>Wallet Balance: SAR {wallet}</p>
                </div>
              )}

              <span className="payment-line"></span>
            </div>
            <div className="container-continue">
            <button className="continue-button" onClick={handleContinueClick}>
              Continue
                    </button>
            </div>
          </>
        ) : (
          <p className="thanks-greeting">Thank You for your payment !</p>
        )}
      </div>
      <div className="save-back-btn-class">
        <button type="button" className="back-btn" onClick={() => navigate(-1)}>
          Back
        </button>
      </div>
    </div>
  );
};

export default InvoiceDetail;
