import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Users.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Users() {
  const [users, setUsers] = useState([]);
  const [filterName, setFilterName] = useState("");
  const [filterEmail, setFilterEmail] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch(`${BASE_URL}/users`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        const data = await response.json();
        if (response.ok) {
          setUsers(data.users);
        } else {
          console.log(data.error);
        }
      } catch (err) {
        console.error("Error:", err.message);
      }
    };

    fetchUsers();

    return () => {
      console.log("Cleaning up...");
    };
  }, []);

  useEffect(() => {
    const applyFilters = () => {
      const filtered = users.filter((user) => {
        const matchesName = user.name
          .toLowerCase()
          .includes(filterName.toLowerCase());
        const matchesEmail = user.email
          .toLowerCase()
          .includes(filterEmail.toLowerCase());
        const matchesStatus =
          filterStatus === "" ||
          (filterStatus === "enabled" && user.status === 1) ||
          (filterStatus === "disabled" && user.status === 0);

        return matchesName && matchesEmail && matchesStatus;
      });

      setFilteredUsers(filtered);
    };

    applyFilters();
  }, [filterName, filterEmail, filterStatus, users]);

  const handleDelete = async (email) => {
    if(email==="Admin@ebillsaudia.com"){
      toast.error("You are not allowed to delete Admin");
      return;
    }
    const confirmDelete = window.confirm(
      `Are you sure you want to delete ${email}?`
    );
    if (confirmDelete) {
      try {
        const response = await fetch(`${BASE_URL}/users/${email}`, {
          method: "DELETE",
        });
        const result = await response.json();
        if (response.ok) {
          toast.success(result.message);
          setUsers(users.filter((user) => user.email !== email));
        } else {
          alert(result.error);
        }
      } catch (err) {
        console.error("Error:", err.message);
      }
    }
  };

  return (
    <div className="users-list">
      <h2>User Lists</h2>
      <div className="header-container-sub">
        <Link to="/dashboard/add-users">
          <button className="add-user-btn">Add Users</button>
        </Link>

        <div className="filter-section">
          <input
            type="text"
            placeholder="Search by Name"
            value={filterName}
            onChange={(e) => setFilterName(e.target.value)}
          />
          <input
            type="text"
            placeholder="Search by Email"
            value={filterEmail}
            onChange={(e) => setFilterEmail(e.target.value)}
          />
          <select
            value={filterStatus}
            onChange={(e) => setFilterStatus(e.target.value)}
          >
            <option value="">All Status</option>
            <option value="enabled">Enabled</option>
            <option value="disabled">Disabled</option>
          </select>
        </div>
      </div>
      <div className="subscriptions-table">
        <table className="user-table">
          <thead>
            <tr>
              <th>S No.</th>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.length === 0 ? (
              <tr>
                <td colSpan="6">No users found</td>
              </tr>
            ) : (
              filteredUsers.map((user, index) => (
                <tr key={user.email}>
                  <td>{user.id}</td>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td>{user.phone}</td>
                  <td>
                    <span
                      style={{
                        display: "inline-block",
                        padding: "5px 10px",
                        borderRadius: "5px",
                        color: "white",
                        backgroundColor:
                          user.status === 1 ? "#4caf50" : "brown",
                      }}
                    >
                      {user.status === 1 ? "Enabled" : "Disabled"}
                    </span>
                  </td>
                  <td>
                    <div className="user-row-actions">
                      <Link
                        to="/dashboard/edit"
                        state={{ userEmail: user.email }}
                      >
                        <button className="edit-btn">Edit</button>
                      </Link>
                      <button
                        className="delete-btn"
                        onClick={() => handleDelete(user.email)}
                      >
                        Delete
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <ToastContainer position="top-center"/>
    </div>
  );
}

export default Users;
