import React from "react";
import "./Features.css"; // Custom CSS for styling
import { motion } from "framer-motion"; // For animations
import invoiceImage from './images/invoice.png';
import deliverable from "./images/deliverable.png";
import openFolder from "./images/open-folder-with-document.png";
import warehouse from "./images/warehouse.png";
import tag from "./images/tag.png";
import deposit from "./images/deposit.png";
import chat from "./images/chat.png";
import wallet from "./images/wallet.png";
import salary from "./images/salary.png";
import cash from "./images/cash.png";
import profit from "./images/profit.png";
import business from "./images/business.png";
import powerful from "./images/powarful.png";
import customer from "./images/customer-service.png";
import gear from "./images/gear.png";
import log from "./images/log-document.png";
import translation from "./images/translation.png";
import sales from "./images/sales.png";
import HeaderEbill from "./HeaderEbill";
import FooterEbill from "./FooterEbill";
import ScrollToTop from "./ScrollTop";
const features = [
  {
    icon: invoiceImage,  
    title: "Invoicing",
    description: "Create professionally designed invoices, send them to customers, and match payments to invoices.",
  },
  {
    icon: deliverable,  // Image path for Purchase & Expense feature
    title: "Purchase & Expense",
    description: "Keep record of all purchases and expenses and never miss out on your payable.",
  },
  {
    icon: openFolder,  // Image path for Inventory feature
    title: "Inventory",
    description: "Stay on top of your orders and quantities while managing your inventory in real-time.",
  },
  {
    icon: warehouse,  // Image path for Warehousing feature
    title: "Warehousing",
    description: "Manage your inventory from multiple warehouse locations and track available quantity at all times.",
  },
  {
    icon: openFolder,
    title:"Sales Quotes",
    description: "Never miss a sales opportunity. Create and send quotations instantly to potential customers.",
  },
  {
    icon: tag,  // Image path for Purchase Orders feature
    title: "Purchase Orders",
    description: "Create purchase orders and keep track of orders and deliveries at every step.",
  },
  {
    icon:deposit,
    title: "Customer Deposits",
    description: "Record advance deposits from customers and easily settle against future invoices.",
  },
  {
    icon: chat,  // Image path for Supplier Advances feature
    title: "Supplier Advances",
    description: "Make advance payments to suppliers and easily adjust against future bills and expenses.",
  },
  {
    icon: wallet,  // Image path for Payment & Receipt feature
    title: "Payment & Receipt",
    description: "Link payments and receipts to your invoices and bills automatically.",
  },
  {
    icon: cash,  // Image path for Bank & Cash feature
    title: "Bank & Cash",
    description: "Always be aware of how much money you have in your bank account and in cash.",
  },
  {
    icon: salary,  // Image path for Payroll feature
    title: "Payroll",
    description: "Manage employee salaries, allowances, benefits, etc. using one simple and easy-to-use module.",
  },
  {
    icon: profit,  // Image path for General Accounting feature
    title: "General Accounting",
    description: "Run the system both as a non-accounting professional or a hardcore accounting expert.",
  },
  {
    icon: business,  // Image path for Taxes/VAT feature
    title: "Taxes/ VAT",
    description: "Easily organise and track taxes on income and expenses and never miss out on your tax obligations.",
  },
  {
    icon: sales,  // Image path for Business Analytics feature
    title: "Business Analytics",
    description: "Stay on top of your business performance at all times through the powerful dashboard.",
  },
  {
    icon: powerful,  // Image path for Powerful Reports feature
    title: "Powerful Reports",
    description: "Generate meaningful standard and custom reports to aid in your business decision making.",
  },
  {
    icon: customer,  // Image path for Manage Contacts feature
    title: "Manage Contacts",
    description: "Stay connected with your customers and suppliers. Always know what you owe and what you are owed.",
  },
  {
    icon: gear,  // Image path for Customization feature
    title: "Customization",
    description: "Customize the application to match your business processes.",
  },
  {
    icon: log,  // Image path for Activity Logs feature
    title: "Activity Logs",
    description: "Keep track of all activities by all users and keep a record of all actions taken while using the system.",
  },
  {
    icon: translation,  // Image path for Multi-Language feature
    title: "Multi-Language",
    description: "Operate the system and generate reports in different available languages.",
  },
];


const Features = () => {
  return (
    <>
    <HeaderEbill/>
    <div className="features-container">
      <div className="heading-container">
        <h2>
          <span style={{ color: "#0f4b60" ,fontWeight:"bold"}}>Features</span>
        </h2>
        <div className="lines"></div>
      </div>
      <div className="features-grid">
        {features.map((feature, index) => (
          <motion.div
          className="feature-item"
          key={index}
          initial={{ opacity: 0, scale: 0.2 }} 
          animate={{
            opacity: 1,
            scale: 1, 
          }}
          transition={{
            duration: 0.2, 
            delay: index * 0.1, 
          }}
        >
          <div className="feature-icon">
            <img src={feature.icon} alt={feature.title} />
          </div>
          <h5 className="feature-title">{feature.title}</h5>
          <p className="feature-description">{feature.description}</p>
        </motion.div>
        ))}
      </div>
    </div>
    <FooterEbill/>
    <ScrollToTop/>
    </>
  );
};

export default Features;
