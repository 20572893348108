import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./AddProducts.css";
function AddProducts() {
  const navigate = useNavigate();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [userDetail, setUserDetail] = useState([]);
  const [planDetail, setPlanDetail] = useState([]);
  // State for user and product information
  const [formData, setFormData] = useState({
    plan_name: "",
    expiry_date: "",
    email: "",
    status: "",
    domain_name: null,
    url: null,
    id: null,
    password: null,
  });
  const handleChange = (e, stateUpdater) => {
    const { name, value } = e.target;
    stateUpdater((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  useEffect(() => {
    fetchDetails();
  }, []);

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      console.log(formData);
      const response = await fetch(`${BASE_URL}/add-user-subscription`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const result = await response.json();
      if (response.ok) {
        alert(result.message);
        navigate(-1);
      } else {
        alert(result.error);
      }
    } catch (error) {
      console.error("Error adding user:", error);
    }
  };
  const fetchDetails = async () => {
    try {
      const response = await fetch(`${BASE_URL}/get/addSubcripions`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const details = await response.json();
      // Check if the response status is OK (200)
      if (response.ok) {
        setUserDetail(details.userDetail);
        setPlanDetail(details.planDetail);
        console.log(details);
      }
    } catch (error) {
      alert(error);
    }
  };

  const handleBack = (e) => {
    e.preventDefault();
    navigate(-1);
  };
  return (
    <div className="edit-contain">
      <h2>Add Subscriptions</h2>
      <div className="container-edit details-container">
        <form onSubmit={handleUpdate} className="edit-form">
          {/* Product Fields */}
          <div className="form-group">
            <label>Select Package:</label>
            <select
              name="plan_name"
              value={formData.plan_name}
              onChange={(e) => handleChange(e, setFormData)}
              required
            >
              <option value="">Select Package</option>
              {planDetail.map((pkg) => (
                <option key={pkg.plan_name} value={pkg.plan_name}>
                  {pkg.plan_name}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label>Expiry Date:</label>
            <input
              type="date"
              name="expiry_date"
              value={formData.expiry_date}
              onChange={(e) => handleChange(e, setFormData)}
              style={{
                width: "100%",
                border: "1px solid rgb(204, 204, 204)",
                borderRadius: "3px",
                padding: "0px 10px",
              }}
              required
            />
          </div>

          {/* User Fields */}
          <div className="form-group">
            <label>User</label>
            <select
              name="email"
              value={formData.email}
              onChange={(e) => handleChange(e, setFormData)}
              required
            >
              <option value="">Select User</option>
              {userDetail.map((user) => (
                <option key={user.email} value={user.email}>
                  {user.name} - {user.email}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label>Domain Name:</label>
            <input
              type="text"
              name="domain_name"
              value={formData.domain_name}
              onChange={(e) => handleChange(e, setFormData)}
            />
          </div>

          <div className="credentials-container">
            <h3>Credentials:-</h3>

            <div className="form-group">
              <label>URL:</label>
              <div className="input-container">
                <input
                  type="text"
                  name="url"
                  value={formData.url}
                  onChange={(e) => handleChange(e, setFormData)}
                />
              </div>
            </div>

            <div className="form-group">
              <label>ID:</label>
              <div className="input-container">
                <input
                  type="text"
                  name="id"
                  value={formData.id}
                  onChange={(e) => handleChange(e, setFormData)}
                />
              </div>
            </div>

            <div className="form-group">
              <label>Password:</label>
              <div className="input-container">
                <input
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={(e) => handleChange(e, setFormData)}
                />
              </div>
            </div>
          </div>
          <div className="form-group">
            <label>Status:</label>
            <select
              name="status"
              value={formData.status}
              onChange={(e) => handleChange(e, setFormData)}
              required
            >
              <option value="">Select Status</option>
              <option value="active">Active</option>
              <option value="progress">Progress</option>
              <option value="suspended">Suspended</option>
              <option value="deleted">Deleted</option>
            </select>
          </div>

          <div className="save-back-btn-class">
            <button className="back-btn" onClick={handleBack}>
              Back
            </button>
            <button type="submit" className="submit-button">
              Save Changes
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddProducts;
