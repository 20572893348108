import React from "react";
import HeaderEbill from "./HeaderEbill";
import FooterEbill from "./FooterEbill";
function Privacypolicy() {
  return (
    <>
      <HeaderEbill />
      <div style={{marginBottom:"100px"}}>
        <div className="guides" style={{paddingBottom:"0px"}}>
          <h1 style={{ color: "#0f4b60" }}>Privacy Policy</h1>
          <div className="line"></div>
        </div>
        <div className="privacy" style={{ padding: "0px 30px" }}>
          <div>
            <h2 style={{ color: "black", fontWeight: "bold",fontSize:"28px",margin:"10px 0px" }}>
              Introduction{" "}
            </h2>
            <p style={{ color: "#0f4b60",fontSize:"18px" }}>
              Our website address is: <a href="/">ebillsaudia</a> E-Bill is a
              cloud based invoicing solution of{" "}
              <a href="https://solexnet.com/">SOLEXNET PRIVATE LIMITED</a>: a
              limited liability company registered with the Companies House in
              The India. (Referred to as “we” or “our” or “us”). Address details
              of our office is available in the Contact Us page. This Privacy
              Statement applies to all services and products that we provide
              including both online and offline products and services,
              (collectively referred to as “Services”) and explains how we
              collect, use, and share information when you interact with us and
              our offerings, services and products. From time to time we may
              change or update our Privacy Statement. We reserve the right to
              make changes or updates at any time. If we make material changes
              to the way we process your personal information, we will notify
              you by posting a notice on our website or by sending you a
              notification on your registered email address available with us.
            </p>
          </div>
          <div>
            <h2 style={{ color: "black", fontWeight: "bold",fontSize:"28px",margin:"10px 0px" }}>
              Data Collection
            </h2>
            <p style={{ color: "#0f4b60",fontSize:"18px" }}>
              The information received by us depends on the degree of
              information that you supply and the options that you choose or
              enter when interacting with us and using our services. Some of the
              ways that your information is collected includes but not limited
              to the following:
            </p>
            <p style={{ color: "#0f4b60",fontSize:"18px" }}>
              Personal and/ or company information: We will collect your
              information when you sign up to use our services or request
              additional information relating to our services. We will collect
              information to verify your identity, including your name, social
              security number, social insurance number, driver’s license
              numbers, and government-issued identification details. You may
              choose not to provide such information. However, in such case you
              may not be able to use our services. Device and location
              information: We may collect some information about you
              automatically when you visit our websites or use our services,
              like your IP address and device type. This includes precise
              location information and the device motion information or both.
              Usage and navigation: We may collect information when you navigate
              through our websites and services, including what pages you looked
              at and what links you clicked on and what features you use. This
              information is useful for us and helps us to better understand
              your needs so that we can provide you a better service. Some of
              this information is collected using cookies and similar tracking
              technologies. Content: We will have access to certain information
              about your business financial transactions when you use our
              services. This information will help us in providing customer
              support and training whenever you request us as well as to ensure
              that you have an error free experience when using our services.
              Information obtained from third parties: We may collect personal
              data about you from other sources, such as publicly available
              materials or trusted third parties like our marketing and research
              partners. We use this information to supplement the personal data
              we already hold about you, in order to better inform, personalize
              and improve our services, and to validate the personal data you
              provide. If we don’t collect your personal data, we may be unable
              to provide you with all our services, and some functions and
              features on our websites may not be available to you. If you’re
              someone who doesn’t have a relationship with us, but believes that
              someone has entered your personal data into our websites or
              services, you’ll need to contact them for any questions you have
              about your personal data (including where you want to access,
              correct, amend, or request that the user delete, your personal
              data).
            </p>
          </div>
          <div>
            <h2 style={{ color: "black", fontWeight: "bold",fontSize:"28px",margin:"10px 0px" }}>Data Usage</h2>
            <p style={{ color: "#0f4b60",fontSize:"18px" }}>
              We will only collect and process your personal information only
              where:
            </p>
            <ul>
              <li>We have your consent to do so.</li>
              <li>Verify and authenticate your identity.</li>
              <li> To perform a contract with you for using our services.</li>
              <li>
                The processing of such information is on our legitimate business
                interest(s) and they’re not overridden by your rights.
              </li>
              <li>
                We are required by the legal requirements in accordance with a
                laws and regulations.
              </li>
              <li>
                To operate our websites and provide you with the services that
                you have requested for.
              </li>
              <li>
                To communicate with you for operational matters and to provide
                technical support, as well as providing training.
              </li>
              <li>
                To market our existing and new services to you and requesting
                your feedback.
              </li>
              <li>
                To enhance our services and / or develop new features and
                services.
              </li>
              <li>
                To detect, protect and safeguard any fraudulent and malicious
                activity.
              </li>
              <li>
                To protect the rights, property and safety of EBILL and its
                customers and suppliers and other stakeholders.
              </li>
              <li>
                Advertise our services on our website and/ or third party
                websites and platforms.
              </li>
              <li>
                To conduct research, analyze and report on our website, services
                and users for internal use which we may share publicly or with
                third parties.
              </li>
            </ul>
          </div>
          <div>
            <h2 style={{ color: "black", fontWeight: "bold",fontSize:"28px",margin:"10px 0px" }}>Data Sharing</h2>
            <p style={{ color: "#0f4b60",fontSize:"18px" }}>
              We will never sell your personal or business information to any
              third party. However, we may share your information in the
              following circumstances:
            </p>
            <ul>
              <li>
                Third party service providers and partners who integrate with
                our services and website and who provide technical support.
              </li>
              <li>
                Law enforcement bodies, government agencies, regulators, courts
                or other third parties where we think it’s necessary to comply
                with applicable laws or regulations, or to exercise, establish
                or defend our legal rights. Where possible and appropriate, we
                will notify you of this type of disclosure.
              </li>
              <li>
                An actual or potential buyer (and its agents and advisors) in
                connection with an actual or proposed purchase, merger or
                acquisition of any part of our business
              </li>
              <li>Third parties who jointly promote our services.</li>
              <li>
                When you publically post reviews and give your comments and
                suggestions including posting on social media.
              </li>
              <li>Other people where we have your consent.</li>
            </ul>
          </div>
          <div>
            <h2 style={{ color: "black", fontWeight: "bold",fontSize:"28px",margin:"10px 0px" }}>
              International Data Transfers
            </h2>
            <p style={{ color: "#0f4b60",fontSize:"18px" }}>
              When we share data, it may be transferred to, and processed in,
              countries other than the country you live in, where our data
              hosting provider’s servers are located. These countries may have
              laws different to what you’re used to. Rest assured, where we
              disclose personal data to a third party in another country; we put
              safeguards in place to ensure your personal data remains
              protected. For individuals in the European Economic Area (EEA),
              this means that your data may be transferred outside of the EEA.
              Where your personal data is transferred outside the EEA, it will
              only be transferred to countries that have been identified as
              providing adequate protection for EEA data, or to a third party
              where we have approved transfer mechanisms in place to protect
              your personal data. For further information, please contact us
              using the Contact Us section.
            </p>
          </div>
          <div>
            <h2 style={{ color: "black", fontWeight: "bold",fontSize:"28px",margin:"10px 0px" }}>Security</h2>
            <p style={{ color: "#0f4b60",fontSize:"18px" }}>
              Security is a priority for us when it comes to your personal data.
              We use physical, technical and organizational safeguards designed
              to protect your information.<br></br> However, despite these
              controls, we cannot completely ensure or warrant the security of
              your information. To help protect privacy and the security of your
              information, you may be asked to provide additional information to
              verify your identity and/or ownership rights prior to us
              exercising your data rights. If we are unable to verify your
              identity or ownership rights to the data, we may not be able to
              provide you with data rights until you are able to provide us with
              proper documents.
            </p>
          </div>
          <div>
            <h2 style={{ color: "black", fontWeight: "bold",fontSize:"28px",margin:"10px 0px" }}>
              Data Retention
            </h2>
            <p style={{ color: "#0f4b60" ,fontSize:"18px"}}>
              The length of time we keep your personal data depends on what it
              is and whether we have an ongoing business need or legal
              requirement to retain it. Unless you specifically ask us to delete
              your personal information, we will retain your personal data for
              as long as we have a relationship with you and for a period of
              time afterwards where we have an ongoing business need to retain
              it, in accordance with our data retention policies and practices.
              However, even if you request a deletion we may still retain your
              information as long as necessary to meet any legal requirements,
              defend a legal claim, and/ or protect against fraudulent or
              abusive activity of our services.
            </p>
          </div>
          <div>
            <h2 style={{ color: "black", fontWeight: "bold",fontSize:"28px",margin:"10px 0px" }}>Your Rights</h2>
            <p style={{ color: "#0f4b60",fontSize:"18px" }}>
              We believe that you have rights to the information that relates to
              it and accordingly you have the right to:
            </p>
            <ul>
              <li>
                Know what personal data we hold about you, and to make sure it’s
                correct and up to date.
              </li>
              <li>
                {" "}
                Request a copy of your personal data, or ask us to restrict
                processing your personal data or delete it.
              </li>
              <li>
                Object to our continued processing of your personal data You can
                exercise these rights at any time by making a request from the
                Contact Us section.
              </li>
            </ul>
          </div>
          <div>
            <h2 style={{ color: "black", fontWeight: "bold",fontSize:"28px" ,margin:"10px 0px"}}>Contact Us</h2>
            <p style={{ color: "#0f4b60",fontSize:"18px" }}>
              We’re always keen to hear from you. If you’re curious about what
              personal data we hold about you or you have a question or feedback
              for us on this notice, our websites or services, please get in
              touch through the Contact Us section of the website.
            </p>
          </div>
        </div>
      </div>
      <FooterEbill />
    </>
  );
}

export default Privacypolicy;
