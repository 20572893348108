import React from 'react';
import "./FooterEbill.css";

function FooterEbill() {
  return (
    <div>
      <footer>
    <div className="footer">
      <div className='inside-footer'>
      <div >
        &copy;{" "}
        <a href="/" target="_blank" className="border-bottom">
          E-Bill Saudia
        </a>
        , All Rights Reserved. A Product of{" "}
        <a
          href="https://Solexnet.com"
          target="_blank"
          rel="noopener noreferrer"
          className="border-bottom"
        >
          SOLEXNET
        </a>
      </div>
      <div className="footer-menu">
        <a href="/privacy-policy" target="_blank">Privacy Policy</a>
        <a href="/term-to-use" target="_blank">Terms To Use</a>
        <a href="/refund-policy" target="_blank" style={{borderRight:"none"}}>Refund Policy</a>
      </div>
      </div>
    </div>
  </footer>
      </div>
  )
}

export default FooterEbill
