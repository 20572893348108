import React, { useEffect, useRef, useState } from "react";
import "./ScrollTop.css";
import { FaChevronUp } from "react-icons/fa";

function ScrollTop() {
  const [showScroll, setShowScroll] = useState(false);

  // Show/hide the scroll icon based on scroll position
  useEffect(() => {
    const handleScroll = () => {
      setShowScroll(window.scrollY > 200); // Show button after scrolling 200px
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Scroll to the top smoothly
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scroll effect
    });
  };

  return (
    <div
      className={`scroll-to-top ${showScroll ? "visible" : ""}`}
      onClick={scrollToTop}
    >
      <FaChevronUp />
    </div>
  );
}

export default ScrollTop;

