import React from "react";
import HeaderEbill from "./HeaderEbill";
import FooterEbill from "./FooterEbill";
function Termtouse() {
  return (
    <>
      <HeaderEbill />
      <div style={{ marginBottom: "100px" }}>
        <div className="guides" style={{ paddingBottom: "0px" }}>
          <h1 style={{ color: "#0f4b60" }}>Terms To Use</h1>
          <div className="line"></div>
        </div>
        <div className="privacy" style={{ padding: "0px 30px" }}>
          <div>
            <h2
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: "23px",
                margin: "10px 0px",
              }}
            >
              A. TERMS OF SERVICE
            </h2>
            <p style={{ color: "#0f4b60", fontSize: "18px" }}>
              Thank you for selecting E-BILL (“Services”). These Terms of
              Service (“Agreement”) is a legal agreement between the User (a
              single person, legal entity or its authorized representative),
              identified in the registration process provided as part of the
              registration process (“User”, “Customer”, or “you”), and SOLEXNET
              PRIVATE LIMITED (“Ebill”, “we”, “our”, or “us”). Please read the
              Agreement thoroughly. By using the services you agree to this
              Agreement. If you do not agree to the terms of the agreement, you
              are not granted any rights whatsoever in the Services.
            </p>
          </div>
          <div>
            <h2
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: "23px",
                margin: "10px 0px",
              }}
            >
              B. SERVICES
            </h2>
            <ol style={{ color: "#0f4b60", fontSize: "18px" }}>
              <li>
                E-BILL is an online solution that offers multiple features and
                products (“Services”) both on a standalone basis as well as in a
                combination, for businesses to carry out accounting and business
                related tasks through an online account. Each E-BILL Account can
                only be used to support one business. Our Services consist of
                all the services we provide now or in the future, including our
                online and mobile accounting, financial and business products.
              </li>
              <li>
                We have the right, in our sole discretion, to revise, update, or
                otherwise modify the Services or alter your access to the
                Services. For material changes, to the extent reasonably
                possible, we will provide you with reasonable notice either
                posted on the website (https://ebillsaudia.com) or to the
                Account Administrator’s (as defined below) email address.
              </li>
              <li>
                We may also modify the amount of online storage space you have
                through the Services and the number of times (and the maximum
                duration for which) you may access the Services during a given
                period of time.
              </li>
              <li>
                In the event we need to maintain the security of the system or
                comply with any laws or regulations, we reserve the right to
                modify the Services immediately, and to provide the
                Administrator with electronic or written notice within thirty
                (30) days after any material modifications.
              </li>
              <li>
                You may reject any changes to the Services by discontinuing use
                of the Services to which such changes relate. Your continued use
                of the Services will constitute your acceptance of and agreement
                to any such changes
              </li>
              <li>
                From time to time, we may, at our sole discretion, include new
                and/ or updated beta features (“Beta Features”) in the Services
                for your use (additional fees may apply). You understand and
                agree that your use of the Beta Features is voluntary and we are
                not obligated to provide you with any Beta Features. You
                understand that once you use the Beta Features, you may be
                unable to revert back to the earlier non-beta version of the
                same or similar feature. Additionally, if such reversion is
                possible, you may not be able to return or restore data created
                within the Beta Feature back to the earlier non-beta version.
                The Beta Features are provided on an “as is” basis and may
                contain errors or inaccuracies that could cause failures,
                corruption, or loss of data and/ or information from any
                connected device. You acknowledge and agree that all use of the
                Beta Features is at your sole risk.
              </li>
              <li>
                You may also request for customizations of certain features
                specific to your account. Such customizations may contain errors
                or inaccuracies that could cause failures, corruption, or loss
                of data and/ or information. You acknowledge and agree that all
                use of the customized features is at your sole risk.
              </li>
              <li>
                Use of the Services may be available through a compatible mobile
                device, internet access and may require a software. You agree
                that you are solely responsible for these requirements,
                including any applicable changes, updates and fees as well as
                the terms of your agreement with your mobile device and
                telecommunications provider. We make no warranties or
                representations of any kind, express, statutory or implied as
                to.
              </li>
              <li>
                The availability of telecommunication services from your
                provider and access to the services at any time or from any
                location.
              </li>
              <li>
                Any loss, damage or security intrusion of the telecommunication
                services.
              </li>
              <li>
                Any disclosure of information to third parties or failure to
                transmit any data, communications or settings connected with the
                services.
              </li>
            </ol>
          </div>
          <div>
            <h2
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: "23px",
                margin: "10px 0px",
              }}
            >
              C. ACCOUNT ADMINISTRATOR AND USERS
            </h2>
            <ol style={{ color: "#0f4b60", fontSize: "18px" }}>
              <li>
                When you register to use our Services and accept these terms you
                become an Account Administrator (“Administrator”), if you’re the
                Administrator, you’re the one responsible for paying for the
                subscription.
              </li>
              <li>
                As an Administrator you can invite other users (“invited user”)
                into your E-BILL account to use the Services that you have
                subscribed for. Subject to the permissions assigned by the
                account administrator, an invited user can also invite other
                users to use your E-BILL account. All invited user you invite
                must also accept these terms to use our services.
              </li>
              <li>
                If you are an invited user, you acknowledge and agree that in
                order to provide you with access to and use E-BILL, we may
                provide your access information and account data to the current
                account administrator for your E-BILL account and to the
                replacement administrator for your account. Any other person you
                identified as an authorized user of E-BILL may also have access
                to the account data subject to the access permissions you or the
                account administrator assigned to them.
              </li>
            </ol>
          </div>
          <div>
            <h2
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: "23px",
                margin: "10px 0px",
              }}
            >
              D. RIGHTS AND RESTRICTIONS FOR USING THE SERVICES
            </h2>
            <ol style={{ color: "#0f4b60", fontSize: "18px" }}>
              <li>
                SOLEXNET PRIVATE LIMITED owns everything available in the
                Services unless otherwise stated and excluding content owned by
                others. This includes rights in the design, compilation, and
                look and feel of our Services. It also includes rights in all
                copyrighted works, trademarks, designs, inventions, and other
                intellectual property. You agree not to copy, distribute, modify
                or make derivative works of any of our content or use any of our
                intellectual property rights in any way not expressly permitted
                by us. Until termination of this Agreement and as long as you
                meet any applicable payment obligations and comply with this
                Agreement, we grant to you a personal, limited, nonexclusive,
                non-transferable right and license to use the Services.
              </li>
              <li>
                You agree not to use, nor permit any third party to use, the
                Services in a manner that violates any applicable law,
                regulations or this Agreement, violates the rights of others, is
                likely to cause notoriety, harm or damage to the reputation of
                SOLEXNET PRIVATE LIMITED or could subject SOLEXNET PRIVATE
                LIMITED to liability to third parties. You agree you will not.
              </li>
              <li>
                Provide access to or give any part of the Services to any third
                party.
              </li>
              <li>
                Reproduce, modify, copy, sell, trade, lease, rent or resell the
                Services.
              </li>
              <li>Decompile, disassemble, or reverse engineer the Services.</li>
              <li>
                Make the Services available on any file-sharing or application
                hosting service.
              </li>
              <li>
                Engage in unauthorized collection or use of personal or
                confidential information, including phishing, pharming,
                spidering, and harvesting.
              </li>
              <li>
                Viewing or other use of any Content that, in our opinion, is
                prohibited under this Agreement.
              </li>
              <li>
                Engage in any other activity that places SOLEXNET PRIVATE
                LIMITED in the position of fostering, or having potential or
                actual liability for, illegal activity in any jurisdiction.
              </li>
              <li>
                Attempting to probe, scan, penetrate or test the vulnerability
                of E-BILL system or network or to breach E-BILL’s security or
                authentication measures, whether by passive or intrusive
                techniques.
              </li>
              <li>
                We reserve the right to not authorize and may terminate your use
                of the Services based on reasonable suspicion of your
                activities, business, products or services that are
                objectionable or promote, support or engage in any of the
                restricted uses described above.
              </li>
            </ol>
          </div>
          <div>
            <h2
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: "23px",
                margin: "10px 0px",
              }}
            >
              E. CONTENT
            </h2>
            <ol style={{ color: "#0f4b60", fontSize: "18px" }}>
              <li>
                Content includes any data, information, materials, text,
                graphics, images, music, software, audio, video, works of
                authorship of any kind, that are uploaded, transmitted, posted,
                generated, stored or otherwise made available through the
                Services (“Content”), which will include without limitation any
                Content that account holders (including you) provide through
                your use of the Services. By making your Content available
                through your use of the Services, you grant Ebill Saudia a
                worldwide, royalty-free, non-exclusive license to host and use
                your Content. Archive your Content frequently. You are
                responsible for any lost or unrecoverable Content. You must
                provide all required and appropriate warnings, information and
                disclosures. Ebill Saudia is not responsible for any of your
                Content that you submit through the Services.
              </li>
              <li>
                We may, but have no obligation to, monitor access to or use of
                the Services or Content or to review or edit any Content for the
                purpose of operating the Services, to ensure compliance with
                this Agreement, and to comply with applicable law or other legal
                requirements. We may disclose any information necessary to
                satisfy our legal obligations, protect Ebill Saudia or its
                customers, or operate the Services properly. We, in our sole
                discretion, may refuse to post, remove, or refuse to remove, or
                disable any Content, in whole or in part, that is alleged to be,
                or that we consider to be unacceptable, undesirable,
                inappropriate, or in violation of this Agreement.
              </li>
              <li>
                We own everything put into our services unless otherwise stated
                and excluding content owned by others. This includes rights in
                the design, compilation, and look and feel of our services. It
                also includes rights in all copyrighted works, trademarks,
                designs, inventions, and other intellectual property. You agree
                not to copy, distribute, modify or make derivative works of any
                of our content or use any of our intellectual property rights in
                any way not expressly permitted by us.
              </li>
            </ol>
          </div>
          <div>
            <h2
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: "23px",
                margin: "10px 0px",
              }}
            >
              F. PRICING
            </h2>
            <ol style={{ color: "#0f4b60", fontSize: "18px" }}>
              <li>
                The use of the Services requires you to pay a subscription fee,
                as offered by us and agreed by you or your agent. The
                subscription fee is based on the service type and plan selected
                by you at the time of registration or at any time after
                registration. Our subscription fee will be billed to you on a
                monthly, quarterly, or semi-annual (“billing cycle”) basis. If
                you decide to change the service type and plan in between a
                billing cycle the new price will only be applied to the
                remainder of the billing period.
              </li>
              <li>
                At the time of registration we may offer you a free trial for a
                maximum of 30 days (“Trial Period”) to use the Services. You
                must decide to purchase a license to the Service, at the agreed
                price for your E-BILL account, within the Trial Period. If you
                do not purchase a license to the Services by the end of the
                Trial Period, your information may no longer be available to
                you. If you decide not to purchase the license to E-BILL, you
                will not be able to access or retrieve any of information or
                data you added or created with the Services during the trial
                period.
              </li>
              <li>
                Our price may vary by region as set out in the pricing page or
                the offer details for customized plans. We may update or amend
                our price from time to time. Any changes to the pricing will not
                apply retrospectively. If we make any changes to our prices and
                you are a subscriber, we will provide you with reasonable notice
                through an email to the account administrator’s email address.
              </li>
              <li>
                You’re responsible for paying all other external fees and taxes
                associated with your use of our services wherever levied. Your
                responsibility includes withholding tax if it applies, unless we
                already process that withholding tax. We may collect
                geographical location information to determine your location,
                which may be used for tax purposes. This means location
                information you give us must be accurate for tax residency
                purposes.
              </li>
              <li>
                In order to continue accessing our services, you need to make
                timely payments based on the pricing plan you selected. To avoid
                delayed or missed payments, please make sure we have accurate
                payment information. If we don’t receive timely payments, we may
                suspend access to your subscription until the payment is made.
              </li>
              <li>
                Payment of the subscription fee must be paid by a valid credit
                or debit card or a direct bank wire transfer to a bank account
                identified in the invoice issued by us.
              </li>
            </ol>
          </div>
          <div>
            <h2
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: "23px",
                margin: "10px 0px",
              }}
            >
              G. SECURITY
            </h2>
            <ol style={{ color: "#0f4b60", fontSize: "18px" }}>
              <li>
                To help protect our services and your data, we have invested in
                technical, physical and administrative safeguards to do our part
                to help keep your data safe and secure. While we have taken
                steps to help protect your data, no method of electronic storage
                is completely secure and we cannot guarantee absolute security.
                We may notify you if we have reason to believe that someone has
                accessed (or may be able to access) your account without
                authorization and we may also restrict access to certain parts
                of our services until you verify that access was by an
                authorized user.
              </li>
              <li>
                We may introduce security features to make your account more
                secure, such as multi-factor authentication. Depending on where
                you are in the world or what services you’re using, we may
                require you to adopt some of these features. Where we make the
                use of security features optional, you’re responsible (meaning
                we’re not liable) for any consequences of not using those
                features. We strongly encourage you to use all optional security
                features.
              </li>
              <li>
                You are responsible for securely managing your password(s) for
                the Services and to contact us if you become aware of any
                unauthorized access to your account. You also agree not to use
                free-form fields in any of our systems or services to store
                personal data (unless it’s a field explicitly asking for
                personal data – like a first name or a last name), credit card
                details, tax identifiers or bank account details.
              </li>
            </ol>
          </div>
          <div>
            <h2
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: "23px",
                margin: "10px 0px",
              }}
            >
              H. MAINTENANCE, DOWNTIME AND DATA LOSS
            </h2>
            <ol style={{ color: "#0f4b60", fontSize: "18px" }}>
              <li>
                We strive to maintain the availability of our services, and
                provide online support, 24 hours a day. On occasion, we need to
                perform maintenance on our services, and this may require a
                period of downtime. We try to minimize any such downtime. Where
                planned maintenance is being undertaken, we’ll attempt to notify
                you in advance but can’t guarantee it. You also may have
                occasional access issues and may experience data loss, so
                backing up your data is important.
              </li>
              <li>
                <strong>Access issues:</strong> You know how the internet works
                – occasionally you might not be able to access our services and
                your data. This might happen for any number of reasons, at any
                time.
              </li>
              <li>
                <strong>Data loss:</strong>
                Data loss is an unavoidable risk when using any technology.
                You’re responsible for maintaining copies of your data entered
                into our services.
              </li>
              <li>
                <strong>No compensation:</strong>
                Whatever the cause of any downtime, access issues or data loss,
                your only recourse is to discontinue using our services.
              </li>
            </ol>
          </div>
          <div>
            <h2
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: "23px",
                margin: "10px 0px",
              }}
            >
              I. CONFIDENTIALITY AND PRIVACY
            </h2>
            <ol style={{ color: "#0f4b60", fontSize: "18px" }}>
              <li>
                We take reasonable precautions to protect your confidential
                information and expect that you’ll do the same for ours.
              </li>
              <li>
                While using our services, you may share confidential or
                proprietary technical and business information and materials
                (“Confidential Information”) with us, and you may become aware
                of Confidential Information about Ebill Saudia and its Services.
                Each of us, its agents and employees shall hold and maintain in
                strict confidence all Confidential Information, shall not
                disclose Confidential Information to any third party, and shall
                not use any Confidential Information except as may be necessary
                to perform its obligations under this agreement. You or we may
                share each other’s confidential information with legal or
                regulatory authorities if required to do so.
              </li>
              <li>
                We may use our customer’s name publically to identify it as a
                customer in connection with specific Services or otherwise,
                solely for the purpose of promotion. You may request us not to
                use your name by sending us an email at info@ebillsaudia.com.
              </li>
              <li>
                Confidential Information shall not include any information that
                is in the public domain or becomes publicly known through no
                fault of the receiving party, or is otherwise properly received
                from a third party without an obligation of confidentiality.
              </li>
              <li>
                You acknowledge that we are the controller of the personal
                information it processes about you when you use the Services.
                You also acknowledge that we will process your personal
                information as described in our Privacy Policy when you use our
                Services.
              </li>
            </ol>
          </div>
          <div>
            <h2
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: "23px",
                margin: "10px 0px",
              }}
            >
              J. DISCLAIMER OF WARRANTIES
            </h2>
            <ol style={{ color: "#0f4b60", fontSize: "18px" }}>
              <li>
                Your use of the Services, software, and content is entirely at
                your own risk. except as described in this agreement, the
                services are provided “as is.” to the maximum extent permitted
                by applicable law, SOLEXNET PRIVATE LIMITED, its affiliates, and
                its and their third party providers, licensors, distributors or
                suppliers (collectively, “suppliers”) disclaim all warranties,
                express or implied, including any warranty that the services are
                fit for a particular purpose, title, merchantability, data loss,
                non-interference with or non-infringement of any intellectual
                property rights, or the accuracy, reliability, quality of
                content in or linked to the services. SOLEXNET PRIVATE LIMITED
                and its affiliates and suppliers do not warrant that the
                services are secure, free from bugs, viruses, interruption,
                errors, theft or destruction. If the exclusions for implied
                warranties do not apply to you, any implied warranties are
                limited to 60 days from the date of purchase or delivery of the
                services, whichever is sooner.
              </li>
              <li>
                SOLEXNET PRIVATE LIMITED, its affiliates and suppliers disclaim
                any representations or warranties that your use of the services
                will satisfy or ensure compliance with any legal obligations or
                laws or regulations.
              </li>
            </ol>
          </div>
          <div>
            <h2
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: "23px",
                margin: "10px 0px",
              }}
            >
              K. LIMITATION OF LIABILITY AND INDEMNITY
            </h2>
            <ol style={{ color: "#0f4b60", fontSize: "18px" }}>
              <li>
                To the maximum extent permitted by applicable law, the entire
                liability of SOLEXNET PRIVATE LIMITED, its affiliates and
                suppliers for all claims relating to this agreement shall be
                limited to the amount you paid for the services during the three
                (3) months prior to such claim. Subject to applicable law,
                SOLEXNET PRIVATE LIMITED, its affiliates and suppliers are not
                liable for any of the following: (a) indirect, special,
                incidental, exemplary, punitive or consequential damages; (b)
                damages relating to failures of telecommunications, the
                internet, electronic communications, corruption, security, loss
                or theft of data, viruses, spyware, loss of business, revenue,
                profits or investment, or use of software or hardware that does
                not meet E-BILL systems requirements. The above limitations
                apply even if SOLEXNET PRIVATE LIMITED and its affiliates and
                suppliers have been advised of the possibility of such damages.
                This agreement sets forth the entire liability of SOLEXNET
                PRIVATE LIMITED, its affiliates and your exclusive remedy with
                respect to the services and its use.
              </li>
              <li>
                You agree to indemnify and hold SOLEXNET PRIVATE LIMITED and its
                Affiliates and Suppliers harmless from any and all claims,
                liability and expenses, including reasonable attorneys’ fees and
                costs, arising out of your use of the Services or breach of this
                Agreement (collectively referred to as “Claims”). SOLEXNET
                PRIVATE LIMITED reserves the right, in its sole discretion and
                at its own expense, to assume the exclusive defense and control
                of any Claims. You agree to reasonably cooperate as requested by
                SOLEXNET PRIVATE LIMITED in the defense of any Claims.
              </li>
            </ol>
          </div>
          <div>
            <h2
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: "23px",
                margin: "10px 0px",
              }}
            >
              L. TERM AND TERMINATION
            </h2>
            <ol style={{ color: "#0f4b60", fontSize: "18px" }}>
              <li>
                We may, at our sole discretion and without notice, restrict,
                deny, terminate this Agreement or suspend the Services, related
                or other Services, effective immediately, in whole or in part,
                if we determine that your use of the Services violates the
                Agreement, is improper or substantially exceeds or differs from
                normal use by other users, raises suspicion of fraud, misuse,
                security concern, illegal activity or unauthorized access
                issues, to protect the integrity or availability of the Services
                or systems and comply with applicable SOLEXNET PRIVATE
                LIMITED.’s policy, if you no longer agree to receive electronic
                communications, or if your use of the Services conflicts with
                our interests or those of another user of the Services. Upon our
                notice that your use of the Services has been terminated you
                must immediately stop using the Services and any outstanding
                payments will become due. Any termination of this Agreement
                shall not affect our rights to any payments due to us. E-BILL
                may terminate a free account at any time.
              </li>
              <li>
                You can easily terminate your subscription with one month’s
                written notice. We may terminate your subscription as well with
                the same notice. If you violate these terms, we may terminate
                your subscription immediately.
              </li>
              <li>
                Subscription period: Your subscription continues for the period
                covered by the subscription fee paid or payable. At the end of
                each billing period, these terms automatically continue for a
                further period of the same duration as the previous one,
                provided you continue to pay the subscription fee in accordance
                with the pricing plan. You may choose to terminate your
                subscription at any time by providing one month’s written notice
                in advance. You’ll still need to pay all relevant subscription
                fees up to and including the day of termination. For more
                information, including on how to terminate your subscription
                send an email to info@ebillsaudia.com.
              </li>
              <li>
                <strong>Termination by us:</strong>We may choose to terminate
                your subscription at any time by providing you with one month’s
                written notice in advance. We may also terminate or suspend your
                subscription or access to all or any data immediately if:
              </li>
              <li>
                you breach any of these terms and do not remedy the breach
                within 14 days after receiving notice of the breach,
              </li>
              <li>
                you breach any of these terms and the breach cannot be remedied,
              </li>
              <li>you fail to pay subscription fees, or</li>
              <li>
                you or your business become insolvent, your business goes into
                liquidation or has a receiver or manager appointed over any of
                its assets, you become insolvent or make any arrangement with
                your creditors, or become subject to any similar insolvency
                event in any jurisdiction.
              </li>
              <li>
                <strong>No refunds: </strong>No refund is due to you if you
                terminate your subscription or we terminate it in accordance
                with these terms.
              </li>
              <li>
                <strong>Retention of your data: </strong>
                Once a subscription is terminated by you or us, it is archived
                and the data submitted or created by you is no longer available
                to you. We retain it for a period of time consistent with our
                data retention policy, during which, as a subscriber, you can
                reactivate your subscription and once again access your data by
                paying the subscription fees. We retain data in case you need it
                as part of your record retention obligations, but you can get in
                touch with us to have your data removed completely if you wish.
              </li>
            </ol>
          </div>
          <div>
            <h2
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: "23px",
                margin: "10px 0px",
              }}
            >
              M. GOVERNING LAW AND JURISDICTION
            </h2>
            <ol style={{ color: "#0f4b60", fontSize: "18px" }}>
              <li>
                This Agreement will be governed by the laws of United Kingdom,
                without regard to its conflicts of law principles.
                Notwithstanding the foregoing, you acknowledge that your breach
                (or an apprehension of breach) of any provisions of this
                Agreement, or any infringement (or apprehension of infringement)
                of SOLEXNET PRIVATE LIMITED or its Suppliers’ intellectual
                property rights may cause SOLEXNET PRIVATE LIMITED irreparable
                damage for which recovery of money damages would be inadequate.
                Accordingly, you agree that SOLEXNET PRIVATE LIMITED shall be
                entitled, in addition to any other remedies available to it, to
                seek (in any court of competent jurisdiction, notwithstanding
                the previous sentence) any relief (whether equitable or
                otherwise) to prevent or restrain any such breach or apprehended
                breach by you or otherwise to protect SOLEXNET PRIVATE
                LIMITED.’s rights under this Agreement. The parties hereby
                submit to the exclusive jurisdiction of the courts of the UK and
                you agree that you will procure that any third party making a
                claim against SOLEXNET PRIVATE LIMITED arising out of this
                Agreement shall bring such claim exclusively in the courts of
                the UK and subject to the limitations and exclusions of
                liabilities provided for in this Agreement.
              </li>
              <li>
                SOLEXNET PRIVATE LIMITED does not represent that the Services
                and/or content within the Services is appropriate or available
                for use in all jurisdictions or countries. SOLEXNET PRIVATE
                LIMITED prohibits accessing content from within countries or
                states where such content is illegal. You are responsible for
                compliance with all applicable laws pertaining to your use and
                access to the Services in your jurisdiction.
              </li>
              <li>
                <strong>Dispute resolution:</strong> Most of your concerns can
                be resolved quickly and to everyone’s satisfaction by contacting
                our support team. If we’re unable to resolve your complaint to
                your satisfaction (or if we haven’t been able to resolve a
                dispute we have with you after attempting to do so informally),
                you and we agree to resolve those disputes through binding
                arbitration or small claims court instead of in courts of
                general jurisdiction. You and we agree that any dispute must be
                brought in the parties’ individual capacity and not as a
                plaintiff or class member in any purported class or
                representative proceeding.
              </li>
              <li>
                This Agreement is the entire agreement between you and SOLEXNET
                PRIVATE LIMITED regarding its subject matter and replaces all
                prior understandings, communications and agreements, oral or
                written, regarding its subject matter. If any court of law,
                having the jurisdiction, rules that any part of this Agreement
                is invalid, that section will be removed without affecting the
                remainder of the Agreement. The remaining terms will be valid
                and enforceable.
              </li>
              <li>
                The United Nations Convention on Contracts for the International
                Sale of Goods and the Uniform Computer Information Transactions
                Act (UCITA) does not apply to this Agreement.
              </li>
            </ol>
          </div>
          <div>
            <h2
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: "23px",
                margin: "10px 0px",
              }}
            >
              N. GENERAL
            </h2>
            <ol style={{ color: "#0f4b60", fontSize: "18px" }}>
              <li>
                <strong>No Professional Advice:</strong> Unless specifically
                included with the Services, we are not in the business of
                providing legal, financial, accounting, tax, health care, real
                estate or other professional services or advice. Consult the
                services of a competent professional when you need this type of
                assistance. We may however, provide you with information we
                think might be useful in performing your financial and business
                tasks on E-BILL, but this should not be seen as a substitute for
                professional advice and we are not liable for your use of the
                information in that way.
              </li>
              <li>
                <strong>Communications:</strong> We may be required by law to
                send you communications about the Services or third party
                products. You agree that we may send these communications to you
                via email or by posting them on our websites. We may also offer
                you, other services, products, or promotions by SOLEXNET PRIVATE
                LIMITED and its affiliates and partners. Additional terms and
                conditions and fees may apply. Any notice you send to SOLEXNET
                PRIVATE LIMITED must be sent to info@ebillsaudia.com. Any
                notices we send to you will be sent to the email address of the
                Account Administrator.
              </li>
              <li>
                <strong>Public Forums and Social media:</strong> Our Services
                may include a community forum(s) or other social features to
                exchange Content and information with other users of the
                Services and the public. We do not support and are not
                responsible for the Content in these community forums. Please
                use respect when you interact with other users. Do not reveal
                information that you do not want to make public. Users may post
                hypertext links to content of third parties for which we are not
                responsible.
              </li>
              <li>
                <strong>Feedback and Suggestions:</strong> You agree that we may
                use your feedback, suggestions, or ideas in any way, including
                in future modifications of the Services, other products or
                services, advertising or marketing materials. You grant SOLEXNET
                PRIVATE LIMITED a perpetual, worldwide, fully transferable,
                sub-licensable, non-revocable, fully paid-up, royalty free
                license to use the feedback you provide to us in any way.
              </li>
              <li>
                <strong>Force Majeure Event:</strong> SOLEXNET PRIVATE LIMITED
                shall not be deemed in breach of this Agreement if we are unable
                to provide the Services or any portion thereof by reason of
                fire, earthquake,labour dispute, act of God or public enemy,
                death, illness or incapacity of SOLEXNET PRIVATE LIMITED or any
                local, provincial, national or international law, governmental
                order or regulation or any other event beyond our control
                (collectively, “Force Majeure Event”).
              </li>
              <li>
                <strong>Assignment:</strong> You may not assign or transfer this
                Agreement to anyone without our written approval. However, we
                may assign or transfer this Agreement without your consent to
                (a) an affiliate, (b) a company through a sale of assets by
                SOLEXNET PRIVATE LIMITED or (c) a successor by merger. Any
                assignment in violation of this Section shall be void. If you
                want to request a transfer of this Agreement, contact us via an
                email to: support@ebillsaudia.com.
              </li>
              <li>
                <strong>
                  Blocking your access, disabling your subscription, or refusing
                  to process a payment:
                </strong>{" "}
                As our sites are global, different laws may apply in different
                countries that restrict our relationship with you. We may block
                your access, terminate your subscription, or refuse to process a
                payment if we reasonably believe there’s a risk – like a
                potential breach of a law or regulation – associated with you,
                your company, your subscription, or a payment. Examples of where
                we might do this include transactions where the payment is from
                a sanctioned person or country; or where we reasonably believe
                there is a legal or regulatory risk or a risk of loss being
                suffered by us or our customers or partners. You promise that
                you’re not located in a sanctioned country and are not on a
                sanctioned persons list. We may also block users from a country
                if we can’t receive payments from that country. You should check
                what payment methods are available in your country for making
                payments. We may take any of these actions without notice.
              </li>
              <li>
                <strong>Independent Service Provider:</strong> Nothing in these
                terms is to be construed as constituting a partnership, joint
                venture, employment or agency relationship between you and us,
                or between you and any other subscriber or invited user. You’re
                solely responsible for resolving disputes between you and any
                other subscriber or invited user. We may assign these terms – or
                any of our rights or obligations in these terms – to another
                SOLEXNET PRIVATE LIMITED.’s affiliate entity as it deems
                appropriate.
              </li>
              <li>
                <strong>Language:</strong> Any translation of this Agreement is
                done for local requirements and in the event of a dispute
                between the English and any non-English version, the English
                version of this Agreement shall govern. In the event of a
                dispute the parties confirm that they have requested that this
                Agreement and all related documents be drafted in English.
              </li>
            </ol>
          </div>
          <div>
            <h2
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: "23px",
                margin: "10px 0px",
              }}
            >
              O. CHANGES
            </h2>
            <p style={{ color: "#0f4b60", fontSize: "18px" }}>
              We reserve the right to modify this Agreement, in our sole
              discretion, at any time, and the modifications will be effective
              when posted through the Services or on our website for the
              Services or when we notify you by other means. We may also change
              or discontinue the Services, in whole or in part. It is important
              that you review this Agreement whenever we modify it because your
              continued use of the Services indicates your agreement to the
              modifications.
            </p>
          </div>
          <div>
            <h2
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: "23px",
                margin: "10px 0px",
              }}
            >
              P. CONFIDENTIALITY AND PRIVACY
            </h2>
            <ol style={{ color: "#0f4b60", fontSize: "18px" }}>
              <li>
                We take reasonable precautions to protect your confidential
                information and expect that you’ll do the same for ours.
              </li>
              <li>
                While using our services, you may share confidential or
                proprietary technical and business information and materials
                (“Confidential Information”) with us, and you may become aware
                of Confidential Information about Ebill Saudia and its Services.
                Each of us, its agents and employees shall hold and maintain in
                strict confidence all Confidential Information, shall not
                disclose Confidential Information to any third party, and shall
                not use any Confidential Information except as may be necessary
                to perform its obligations under this agreement. You or we may
                share each other’s confidential information with legal or
                regulatory authorities if required to do so.
              </li>
              <li>
                We may use our customer’s name publically to identify it as a
                customer in connection with specific Services or otherwise,
                solely for the purpose of promotion. You may request us not to
                use your name by sending us an email at info@ebillsaudia.com.
              </li>
              <li>
                Confidential Information shall not include any information that
                is in the public domain or becomes publicly known through no
                fault of the receiving party, or is otherwise properly received
                from a third party without an obligation of confidentiality.
              </li>
              <li>
                You acknowledge that we are the controller of the personal
                information it processes about you when you use the Services.
                You also acknowledge that we will process your personal
                information as described in our Privacy Policy when you use our
                Services.
              </li>
            </ol>
          </div>
        </div>
      </div>
      <FooterEbill />
    </>
  );
}

export default Termtouse;
