import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import "./Payments.css";
import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js";
import { IoWallet } from "react-icons/io5";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Payments() {
  const navigate = useNavigate();
  const location = useLocation();
  const { selectedPlan , subscription_id ,selectedPayment} = location.state || {}; 
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [user, setUser] = useState({});
  const [toggleNext, setToggleNext] = useState(false);
  const [isPaid, setIsPaid] = useState(false);
  const [loading, setLoading] = useState(false);
  const token = Cookies.get("token");
  const [paymentMethod, setPaymentMethod] = useState(selectedPayment || "paypal");
  const style = { layout: "vertical" };
  const [agreementChecked, setAgreementChecked] = useState({
    serviceAgreement: false,
    privacyPolicy: false,
  });
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setAgreementChecked((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  // Fetch user details
  useEffect(() => {
    const fetchUserDetails = async () => {
      if (!token) return;
      const decodedToken = jwtDecode(token);
      const userEmail = decodedToken.email;
      try {
        const response = await fetch(`${BASE_URL}/edit/${userEmail}`);
        const result = await response.json();
        if (response.ok) {
          setUser(result.user);
        } else alert(result.error);
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };
    fetchUserDetails();
  }, [BASE_URL, token]);

  const handleNext = () => setToggleNext(true);

  const createOrder = async () => {
    try {
      const response = await fetch(`${BASE_URL}/create-order`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          amount: totalWithCommission, // Send plan price
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to create the order");
      }

      const order = await response.json();
      return order.id; // Return the order ID for use in the onApprove function
    } catch (error) {
      console.error("Error creating order:", error);
      alert("There was an issue creating the order. Please try again.");
    }
  };

  const onApprove = async (data) => {
    try {
      const response = await fetch(`${BASE_URL}/capture-order`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          orderID: data.orderID,
          userEmail: user.email, // Pass user email
          planName: selectedPlan.plan_name, // Pass plan name
          amount: totalWithCommission, // Pass amount
          duration: selectedPlan.duration, // Pass duration
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to capture the order");
      }

      const orderData = await response.json();
      setIsPaid(true);
      window.close();
    } catch (error) {
      console.error("Error capturing the order:", error);
      alert("There was an issue processing the payment. Please try again.");
    }
  };
  const commission = (selectedPlan.amount * 0.03).toFixed(2);
  // Ensure commission and selectedPlan.amount are numbers
  const totalWithCommission = (
    Number(commission) + Number(selectedPlan.amount)
  ).toFixed(2);

  const handleWallet = async () => {
    if (
      !agreementChecked.serviceAgreement ||
      !agreementChecked.privacyPolicy
    ) {
      toast.error("You must agree to the terms and policies before proceeding.");
      return;
    }
    if (!window.confirm("Are you sure you want to pay using your wallet?")) {
      return;
    }
    try {
      const response = await fetch(`${BASE_URL}/api/save-wallet`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: user.email,
          planName: selectedPlan.plan_name,
          amount: selectedPlan.amount,
          duration: selectedPlan.duration,
          subscription_id:subscription_id
        }),
      });
      const result = await response.json();
      if (response.ok) {
        alert(result.message);
        setIsPaid(true);
      } else {
        toast.error(result.message);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const ButtonWrapper = ({ showSpinner }) => {
    const [{ isPending }] = usePayPalScriptReducer();

    return (
      <>
        {showSpinner && isPending && <div className="spinner" />}
        <PayPalButtons
          style={style}
          createOrder={createOrder}
          onApprove={onApprove}
        />
      </>
    );
  };
  return (
    <div className="edit-contain">
      {!isPaid && <h2>Payment</h2>}
      {!toggleNext ? (
        <div className="container-edit details-container payments">
          <div className="edit-form">
            <div className="form-group">
              <label>Name:</label>
              <p>{user.name || ""}</p>
            </div>
            <div className="form-group">
              <label>Email:</label>
              <p>{user.email || ""}</p>
            </div>
            <div className="form-group">
              <label>Phone:</label>
              <p>{user.phone || ""}</p>
            </div>
            <div className="form-group">
              <label>Status:</label>
              <div
                style={{
                  color: user.status === 1 ? "green" : "brown",
                  fontWeight: "bold",
                }}
              >
                {user.status === 1 ? "Enabled" : "Disabled"}
              </div>
            </div>
            <div className="form-group">
              <label>Address:</label>
              <p>{user.address || ""}</p>
            </div>
            <div className="save-back-btn-class">
              <button
                type="button"
                className="back-btn"
                onClick={() => navigate(-1)}
              >
                Back
              </button>
              <button
                type="button"
                className="submit-button"
                onClick={handleNext}
                disabled={loading}
              >
                {loading ? "Processing Payment..." : "Next for Payment"}
              </button>
            </div>
          </div>
          <p className="para-text">
            If you want to change your details, you can update them from the
            <Link to="/dashboard/profile"> profile</Link> section.
          </p>
        </div>
      ) : (
        <div className="payment-confirmation">
          {!isPaid ? (
            <>
              <div className="payment-section">
                <div className="inside-payment-section">
                  <div className="payment-methods">
                    <span className="section-title">Payment Method</span>
                    <div className="payment-options">
                      <div className="option">
                        <input
                          type="radio"
                          name="paymentMethod"
                          value="paypal"
                          checked={paymentMethod === "paypal"}
                          onChange={() => setPaymentMethod("paypal")}
                          id="paypal"
                        />
                        <label for="paypal">Pay using Paypal (+3%)</label>
                      </div>
                      <div className="option">
                        <input
                          type="radio"
                          name="paymentMethod"
                          value="wallet"
                          checked={paymentMethod === "wallet"}
                          onChange={() => setPaymentMethod("wallet")}
                          id="wallet"
                        />
                        <label for="wallet">Pay using wallet</label>
                      </div>
                    </div>
                    {paymentMethod === "wallet" && (
                    <div
                          style={{
                            color: user.wallet_balance < 0 ? "red" : "black",textAlign:"center"
                          }}
                          
                        >
                        Avalable Balance = {user.wallet_balance} SAR 
                        </div>
                    )}
                  </div>

                  <div className="service-agreements">
                    <span className="section-title">Service Agreements</span>
                    <div className="agreements-options">
                      <div className="option services">
                        <input
                          type="checkbox"
                          name="serviceAgreement"
                          checked={agreementChecked.serviceAgreement}
                          onChange={handleCheckboxChange}
                          required
                          id="agree"
                        />
                        <label for="agree">
                          I agree to the{" "}
                          <a href="#">Service and Use Agreement</a>
                        </label>
                      </div>
                      <div className="option services">
                        <input
                          type="checkbox"
                          name="privacyPolicy"
                          checked={agreementChecked.privacyPolicy}
                          onChange={handleCheckboxChange}
                          required
                          id="agrees"
                        />
                        <label for="agrees">
                          I agree to the{" "}
                          <a href="#">
                            Personal Data and General Privacy Policy
                          </a>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="order-summary">
                  <span className="section-title">Order Summary</span>
                  <div className="inside-order-summary">
                    <div className="summary-item">
                      <span style={{ fontWeight: "bold", color: "#0f4b60" }}>
                        Total Order Amount
                      </span>{" "}
                      <span style={{ fontWeight: "bold", color: "#FE9900" }}>
                        {selectedPlan.amount} SAR
                        
                      </span>
                    </div>
                    {paymentMethod === "paypal" ? (
                      <>
                        <div className="summary-item">
                          <span
                            style={{ fontWeight: "bold", color: "#0f4b60" }}
                          >
                            Payment Commission (3%)
                          </span>{" "}
                          <span
                            style={{ fontWeight: "bold", color: "#FE9900" }}
                          >
                            {commission} SAR
                          </span>
                        </div>
                        <div className="summary-item total">
                          <span
                            style={{ fontWeight: "bold", color: "#0f4b60" }}
                          >
                            Total Amount Payable
                          </span>{" "}
                          <span
                            style={{ fontWeight: "bold", color: "#FE9900" }}
                          >
                            {totalWithCommission} SAR
                          </span>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="summary-item">
                          <span
                            style={{ fontWeight: "bold", color: "#0f4b60" }}
                          >
                            Payment Commission
                          </span>{" "}
                          <span
                            style={{ fontWeight: "bold", color: "#FE9900" }}
                          >
                            0
                          </span>
                        </div>
                        <div className="summary-item total">
                          <span
                            style={{ fontWeight: "bold", color: "#0f4b60" }}
                          >
                            Total Amount Payable
                          </span>{" "}
                          <span
                            style={{ fontWeight: "bold", color: "#FE9900" }}
                          >
                            {selectedPlan.amount} SAR
                          </span>
                        </div>
                      </>
                    )}
                    <button className="continue-button">
                      <div className="payment-method-details">
                        {paymentMethod === "paypal" && (
                          <div>
                            {/* <PayPalScriptProvider
                              options={{
                                "client-id":
                                  "Afg_WCTaLDwe4wwMCyAzvDYhqRfHYFK-LXd8blmH36UgcJsDfC3uKor0pS-uZuY2aL-mAAFRIeDGcT2x",
                                currency: "USD",
                                components: "buttons",
                              }}
                            >
                              <ButtonWrapper showSpinner={true} />
                            </PayPalScriptProvider> */}
                          </div>
                        )}
                        {paymentMethod === "wallet" && (
                          <div>
                            {/* Render wallet details or proceed button */}
                            <button
                              className="wallet-button buttons"
                              onClick={handleWallet}
                            >
                              <IoWallet className="icon" /> Continue with Wallet
                            </button>
                          </div>
                        )}
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div>
              <h2>Thank you!</h2>
              <p style={{ color: "green", textAlign: "center" }}>
                Your order has been placed successfully. You can download the
                invoice from the Invoices section.
              </p>
            </div>
          )}
        </div>
      )}
       <ToastContainer position="top-center"/>
    </div>
  );
}

export default Payments;
